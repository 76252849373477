<template>
<div>
    <app-page url="stock" settings="stockSettings" title="Stocks" translate="stock_japanese" modalItemId="modalItem" modalCreateId="modalCreate" :use-map="true">
    </app-page>
</div>
</template>

<script>
import Page from './Page.vue'
export default {
    components: {
        'app-page': Page,
    },

}
</script>

<style lang="scss">
.table-active {
    background-color: unset;
}


.table-striped>tbody>tr:nth-child(odd).selected>td,
.table-striped>tbody>tr:nth-child(odd).selected>th,
.table-striped>tbody>tr:nth-child(even).selected>td,
.table-striped>tbody>tr:nth-child(even).selected>th {
    background-color: rgb(253, 253, 125) !important;
    --bs-table-accent-bg: rgb(253, 253, 125) !important;
}




</style>
