<template>
<section>
    <div class="row">
        <div class="col-12">

            <b-table class="tablesaw" :busy="loading" bordered :items="elements" :fields="fields" striped responsive :select-head="false" :selectable="selectable" @rowSelected="$emit('row-selected', $event)" @rowUnselected="$emit('un-row-selected', $event)" @headClicked="sortChange" show-empty>

                <template #empty="scope">
                    <div v-if="title === 'Stocks'" class="d-flex justify-content-center">
                        一致する情報は見つかりませんでした
                    </div>
                    <div v-else></div>
                </template>

                <template v-slot:head()="data">
                    <template v-if="data.label.indexOf('滞留日数') !== -1">
                        <div style="white-space: pre-wrap;display: flex;">
                            <div>{{ data.label }}</div>
                            <div style="line-height: 45px;">
                                <i-bi-caret-down-fill v-if="fields.find(item=> item.label === data.label) === undefined || fields.find(item=> item.label === data.label).sortDirection === undefined" style="visibility: hidden" />
                                <i-bi-caret-up-fill v-else-if="fields.find(item=> item.label === data.label).sortDirection === 'asc'" />
                                <i-bi-caret-down-fill v-else-if="fields.find(item=> item.label === data.label).sortDirection === 'desc'" />
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div style="white-space: nowrap">
                            <span>{{ data.label }}</span>
                            <i-bi-caret-down-fill v-if="fields.find(item=> item.label === data.label) === undefined || fields.find(item=> item.label === data.label).sortDirection === undefined" style="visibility: hidden" />
                            <i-bi-caret-up-fill v-else-if="fields.find(item=> item.label === data.label).sortDirection === 'asc'" />
                            <i-bi-caret-down-fill v-else-if="fields.find(item=> item.label === data.label).sortDirection === 'desc'" />
                        </div>
                    </template>

                </template>

                <template v-slot:cell(ItemMaster.amountThreshold)="row">
                    <template v-if="currentCategorie === 2">
                        <b-form-input :modelValue="row.value" @input="changeItem($event, row.item, row.field.key)"></b-form-input>
                    </template>
                    <template v-else>
                        <span class="cursor">{{ row.value }}</span>
                    </template>
                </template>

                <template v-slot:cell(ItemMaster.daysThreshold)="row">
                    <template v-if="currentCategorie === 3">
                        <b-form-input :modelValue="row.value" @input="changeItem($event, row.item, row.field.key)"></b-form-input>
                    </template>
                    <template v-else>
                        <span class="cursor">{{ row.value }}</span>
                    </template>
                </template>
                <template v-slot:cell(totalCount)="row">
                    <span >{{  row.item.storeCount +  row.item.backyardCount}}</span>
                </template> 
                
                <template v-slot:cell(storeCount)="row">
                    <span :class="currentCategorie === 2 ? 'cursor red' : 'cursor'">{{ row.value }}</span>
                </template>
                <template v-slot:cell(backyardCount)="row">
                    <span :class="currentCategorie === 2 ? 'cursor red' : 'cursor'">{{ row.value }}</span>
                </template>

                <template v-slot:cell()="row">
                    <template v-if="row.field.key.includes('amountAlertConfirmed') || row.field.key.includes('daysAlertConfirmed')">
                        <b-form-checkbox :modelValue="row.value" @change="changeItem($event, row.item, row.field.key)"></b-form-checkbox>
                    </template>
                    <template v-else>
                        <span class="cursor">{{ row.value }}</span>
                    </template>
                </template>

            </b-table>

            <div class="d-flex justify-content-center align-content-center mt-4">
                <slot name="paginate"></slot>
            </div>

            <slot name="button"></slot>

        </div>
    </div>

</section>
</template>

<script>
import Paginate from '../ThePaginate.vue'
import moment from 'moment';
import {
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BPagination,
    BTable
} from "bootstrap-vue-next";

export default {
    props: ['elements', 'loading', 'headers', 'paginate', 'value', 'title', 'sort', 'selectable', 'currentCategorie'],
    components: {
        Paginate,
        BTable,
        BInputGroup,
        BFormInput,
        BInputGroupText
    },
    data() {
        return {
            paginateCount: 0
        }
    },
    filters: {
        moment: function (date) {
            return date ? moment(date).format('YYYY/MM/DD HH:mm:ss') : '';
        },
        momentDay: function (date) {
            return date ? moment(date).format('YYYY/MM/DD') : '';
        }
    },
    watch: {
        paginateCount: function () {
            this.$emit('updatePaginateCount', this.paginateCount)
        },
    },
    mounted() {
        this.paginateCount = this.value
    },

    computed: {
        fields() {
            let headers = this.headers
                .map((header) => {
                    if (header.source.includes(" as ")) {
                        header.source = header.source.split(" as ")[1]
                    }
                    
                    let index = 0;
                    if (this.sort.map(sort => sort.source).includes(header.source)) {

                        const direction = this.sort.find((item, i) => {
                            index = i;
                            return item.source === header.source;
                        }).direction === 'asc' ? 'asc' : 'desc';
                        return {
                            id: header.id,
                            key: header.source,
                            name: header.name,
                            index: index,
                            direction: direction,
                            class: ['sorting', "word-break-keep", direction],
                            inputType: header.sourceDefine !== null ? (header.sourceDefine.inputType !== undefined ? header.sourceDefine.inputType : 0) : 0
                        }
                    } else {

                        return {
                            id: header.id,
                            key: header.source,
                            name: header.name,
                            class: ['sorting', "word-break-keep"],
                            inputType: header.sourceDefine !== null ? (header.sourceDefine.inputType !== undefined ? header.sourceDefine.inputType : 0) : 0
                        }
                    }
                })
                .map((item) => {
                    const index = item.index >= 0 ? item.index + 1 : '';
                    return {
                        key: item.key,
                        label: `${item.name}`,
                        thClass: item.class,
                        sortDirection: item.direction,
                        thAttr: {
                            index: index
                        },
                        inputType: item.inputType
                    }
                });
            return headers;
        }
    },
    methods: {
        setModalItem(item) {
            this.$emit('setModalItem', item)
        },
        setModalCreate(item) {
            this.$emit('setModalCreate', item);
        },
        updatePaginateCount(value) {
            this.$emit('updatePaginateCount', value)
        },
        sortChange(sort) {
            this.$emit('sortChange', sort)
        },
        changeItem(value, item, key) {
            this.$emit('changeItem', value, item, key)
        }
    }
}
</script>

<style>
/* Busy table styling */
table.b-table[aria-busy='true'] td {
    opacity: 0.7;
}

.sorting {
    cursor: pointer;
    user-select: none
}

.inventoryDeleteItem {
    width: 50px;
}

.inventoryDeleteItem.custom-control {
    padding-left: 2.5rem;
}

.notenough {
    color: red;
    cursor: pointer;
}

.red {
    color: red;
}

.overstock {
    color: #e3c23f;
    cursor: pointer;
}

.number {
    color: #007bff;
    cursor: pointer;
}

.table-amount-alert {
    background-color: #FFCF71 !important;
}

.table-days-alert {
    background-color: #FF8B7B !important;
}
</style>
