<template>
<div>
    <b-table striped responsive borderless :fields="header" :items="attributes">
        <template v-slot:cell(delete)="data">
            <b-form-checkbox v-model="data.item.checked"></b-form-checkbox>
        </template>

        <template v-slot:cell(name)="data">
            <template v-if="data.item && data.item.isDisabled">
                <b-form-input v-model="data.item.name" disabled></b-form-input>
            </template>
            <template v-else>
                <b-form-select v-model="data.item.source" :options="nameList">
                </b-form-select>
            </template>
        </template>
        <template v-slot:cell(list)="data">
            <b-form-input v-model="data.item.list" @input="changeItem($event, data.item, data.field.key)"></b-form-input>
        </template>
        <template v-slot:cell(detail)="data">
            <b-form-input v-model="data.item.detail" @input="changeItem($event, data.item, data.field.key)"></b-form-input>
        </template>
        <template v-slot:cell(item_detail)="data">
            <b-form-input v-model="data.item.item_detail" @input="changeItem($event, data.item, data.field.key)"></b-form-input>
        </template>
        <template v-slot:cell(search_condition)="data">
            <b-form-input v-model="data.item.search_condition" @input="changeItem($event, data.item, data.field.key)"></b-form-input>
        </template>
        <template v-slot:cell(search_result)="data">
            <b-form-input v-model="data.item.search_result" @input="changeItem($event, data.item, data.field.key)"></b-form-input>
        </template>
        <template v-slot:cell(search_title)="data">
            <b-form-input v-model="data.item.search_title" @input="changeItem($event, data.item, data.field.key)"></b-form-input>
        </template>
    </b-table>
    <div class="d-flex justify-content-between px-2">
        <ButtonAdd @click="attributeAddEmptyInput"></ButtonAdd>
        <div>
            <ButtonSave @click="updateOrCreateAttributes()"></ButtonSave>
            <ButtonDelete @click="deleteAttributes"></ButtonDelete>
        </div>
    </div>
</div>
</template>

<script>
import ButtonEdit from "../components/buttons/ButtonEdit.vue";
import ButtonAdd from "../components/buttons/ButtonAdd.vue";
import ButtonDelete from "../components/buttons/ButtonDelete.vue";
import ButtonSave from "../components/buttons/ButtonSave.vue";
import * as queries from "../graphql/locus_queries"
import * as mutations from "../graphql/locus_mutations";
import global from "../Global.vue";
import AwsApi from '../Api';
import {
    API
} from "aws-amplify";

export default {
    components: {
        ButtonSave,
        ButtonDelete,
        ButtonAdd,
        ButtonEdit
    },
    computed: {
        attributes() {
            return [...this.attributesApi, ...this.attributesAdd]
        }
    },
    data() {
        return {
            tenantId: "",
            attributesApi: [],
            attributesAdd: [],
            sourceTable: "ItemMaster",
            sourceTable2: "EPC",
            sourceTable3: "Job",
            header: [{
                key: "delete",
                label: this.$t("select"),
            }, {
                key: "name",
                label: this.$t("attr_name"),
            }, {
                key: "list",
                label: this.$t("attr2_list"),
            }, {
                key: "detail",
                label: this.$t("attr2_detail"),
            }, {
                key: "item_detail",
                label: this.$t("attr2_item_detail"),
            }, {
                key: "search_condition",
                label: this.$t("attr2_search_condition"),
            }, {
                key: "search_result",
                label: this.$t("attr2_search_result"),
            }, {
                key: "search_title",
                label: this.$t("attr2_search_title"),
            }],
            nameList: []
        }
    },
    mounted() {
        this.tenantId = localStorage.getItem(global.tenantId)
        this.fetchAttributes()
    },
    methods: {
        async fetchAttributes() {
            this.attributesApi = []
            this.attributesAdd = []
            const response = await AwsApi.graphql({
                query: queries.listAppItemConfigs,
                variables: {
                    tenantId: this.tenantId
                }
            }, this);
            await this.setNameList()
            this.attributesApi = [...this.attributeMapApiResponse([...response.data.listAppItemConfigs.items])]
        },
        async setNameList() {
            const keyResponse = await AwsApi.graphql({
                query: queries.queryItemDefines,
                variables: {
                    sourceTable: {
                        eq: this.sourceTable
                    },
                    tenantId: this.tenantId
                }
            }, this);
            const keyResponse2 = await AwsApi.graphql({
                query: queries.queryItemDefines,
                variables: {
                    sourceTable: {
                        eq: this.sourceTable2
                    },
                    tenantId: this.tenantId
                }
            }, this);
            const keyResponse3 = await AwsApi.graphql({
                query: queries.queryItemDefines,
                variables: {
                    sourceTable: {
                        eq: this.sourceTable3
                    },
                    tenantId: this.tenantId
                }
            }, this);
            this.nameList = []
            const allKey = [...keyResponse.data.queryItemDefines.items,
             ...keyResponse2.data.queryItemDefines.items,
              ...keyResponse3.data.queryItemDefines.items]

           allKey.forEach((element, index) => {
                this.nameList.push({
                    value: element.source,
                    text: element.name
                })
            });
        },

        attributeMapApiResponse(response) {
            const dataList = []
            response.forEach((element, index) => {
                const current = dataList.filter(item => item.source === element.source)

                if (current.length > 0) {
                    this.setAttrData(current[0], element)
                } else {
                    const newAttr = {}
                    this.setAttrData(newAttr, element)
                    dataList.push(newAttr)
                }
            })

            return dataList
        },
        setAttrData(attr, element) {
            var order = ""
            if (element.order !== undefined && element.order !== null) {
                order = element.order.join(',')
            }

            attr.checked = false
            attr.isDisabled = true
            attr.name = element.name
            attr.source = element.source
            switch (element.screenId) {
                case 0: {
                    attr.list = order
                    attr.list_id = element.id
                    break
                }
                case 1: {
                    attr.detail = order
                    attr.detail_id = element.id
                    break
                }
                case 2: {
                    attr.item_detail = order
                    attr.item_detail_id = element.id
                    break
                }
                case 3: {
                    attr.search_condition = order
                    attr.search_condition_id = element.id
                    break
                }
                case 4: {
                    attr.search_result = order
                    attr.search_result_id = element.id
                    break
                }
                case 5: {
                    attr.search_title = order
                    attr.search_title_id = element.id
                    break
                }
            }
        },

        attributeAddEmptyInput() {
            const emptyAttribute = {
                name: null,
                checked: false,
            };
            this.attributesAdd = [...this.attributesAdd, emptyAttribute]
        },

        async deleteAttributes() {
            this.attributesAdd = this.attributesAdd.filter(attribute => !attribute.checked)
            AwsApi.checkSession(() => {

                const deleteAttr = [];
                this.attributesApi.filter(attribute => attribute.checked).forEach(element => {
                    this.checkIdDelete(deleteAttr, element.list_id)
                    this.checkIdDelete(deleteAttr, element.detail_id)
                    this.checkIdDelete(deleteAttr, element.item_detail_id)
                    this.checkIdDelete(deleteAttr, element.search_condition_id)
                    this.checkIdDelete(deleteAttr, element.search_result_id)
                    this.checkIdDelete(deleteAttr, element.search_title_id)
                })
                Promise.all([...deleteAttr]).then(() => this.fetchAttributes())
            }, this)
        },
        checkIdDelete(deleteAttr, id) {
            if (id !== undefined) {
                deleteAttr.push(API.graphql({
                    query: mutations.deleteAppItemConfig,
                    variables: {
                        input: {
                            id: id,
                            tenantId: this.tenantId
                        }
                    }
                }));
            }
        },

        async updateOrCreateAttributes() {
            AwsApi.checkSession(() => {
                const create = [];
                const update = [];
                this.attributesApi.filter(item => item.toUpdate !== undefined && item.toUpdate === true).forEach(element => {
                    this.createOrUpdateApi(update, element.source, element.list, element.list_id, 0)
                    this.createOrUpdateApi(update, element.source, element.detail, element.detail_id, 1)
                    this.createOrUpdateApi(update, element.source, element.item_detail, element.item_detail_id, 2)
                    this.createOrUpdateApi(update, element.source, element.search_condition, element.search_condition_id, 3)
                    this.createOrUpdateApi(update, element.source, element.search_result, element.search_result_id, 4)
                    this.createOrUpdateApi(update, element.source, element.search_title, element.search_title_id, 5)
                })
                this.attributesAdd.forEach(element => {
                    this.createApi(create, element.source, element.list, 0)
                    this.createApi(create, element.source, element.detail, 1)
                    this.createApi(create, element.source, element.item_detail, 2)
                    this.createApi(create, element.source, element.search_condition, 3)
                    this.createApi(create, element.source, element.search_result, 4)
                    this.createApi(create, element.source, element.search_title, 5)
                })
                Promise.all([...update, ...create]).then(() => this.fetchAttributes())
            }, this)
        },
        createApi(create, source, order, screenId) {
            if (order !== undefined && order !== null && order !== "") {
                create.push(API.graphql({
                    query: mutations.createAppItemConfig,
                    variables: {
                        input: this.createParam(source, order, screenId)
                    }
                }));
            }
        },
        updateApi(update, source, order, id, screenId) {
            update.push(API.graphql({
                query: mutations.updateAppItemConfig,
                variables: {
                    input: this.updateParam(source, order, id, screenId)
                }
            }));
        },
        createOrUpdateApi(update, source, order, id, screenId) {
            if (id === undefined) {
                if (order !== undefined && order !== null && order !== "") {
                    this.createApi(update, source, order, screenId)
                }
            } else {
                if (order !== undefined && order !== null && order !== "") {
                    this.updateApi(update, source, order, id, screenId)
                } else {
                    this.checkIdDelete(update, id)
                }
            }
        },
        checkName(source) {
            var attrName = ""
            const filterName = this.nameList.filter(item => item.value === source)
            if (filterName.length > 0) {
                attrName = filterName[0].text
            }
            return attrName
        },
        checkOrder(order) {
            const regex = /^[0-9,]+$/;
            return regex.test(order);
        },
        createParam(source, order, screenId) {
            return {
                group: this.tenantId,
                tenantId: this.tenantId,
                name: this.checkName(source),
                order: this.checkOrder(order) ? order.toString().split(",").map(item => parseInt(item)) : 1,
                source: source,
                screenId: screenId
            }
        },
        updateParam(source, order, id, screenId) {
            return {
                id: id,
                group: this.tenantId,
                tenantId: this.tenantId,
                name: this.checkName(source),
                order: this.checkOrder(order) ? order.toString().split(",").map(item => parseInt(item)) : 1,
                source: source,
                screenId: screenId
            }
        },
        changeItem(value, cell, key) {
            cell[key] = value;
            cell.toUpdate = true
        },
    }
}
</script>

<style>
.hidden_header {
    display: none;
}
</style>
