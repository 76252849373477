<template>
<VueMultiselect v-if="options !== undefined" v-model="value_"  :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="-- 選択してください --" label="text" track-by="value" :preselect-first="false" @select="emitOptionValue" @remove="emitOptionValue" :searchable="false">

</VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect'

export default {
    components: {
        VueMultiselect
    },
    data() {
        return {
            value_: [],
        }
    },
    mounted() {
    },
    props: ['options', 'modelValue'],
    methods: {
        emitOptionValue(value) {
            let v = this.value_.map(_=>_.value)
            if (v.length === 0) v = null
            this.$emit('update:modelValue', v);
        }
    },
    watch: {
        options: function (newValue) {
            if (this.modelValue !== undefined && newValue !== undefined) {
                this.value_ = this.modelValue.map(_ => newValue[_])
            }

        }
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
    .multiselect {
        min-width: 190px;
        min-height: 35px;
    }
    .multiselect__tags {
        min-height: 38px !important;
        padding: 7px 40px 0 8px !important;

        border: 1px solid #cfcfcf;
        box-shadow: none;
        color: rgba(0, 0, 0, 0.6);
    }
    .multiselect__tag {
        padding: 4px 26px 2px 10px !important;
    }
    .multiselect__placeholder {
        margin-bottom: 6px !important;
    }
</style>
