<script>
import {
    RouterView
} from 'vue-router'
</script>

<template>
<div id="app">
    <RouterView />
</div>
</template>

<style>
.w-100 {
    width: 100% !important;
}

.mr-1 {
    margin-right: .25rem !important
}

.mr-2 {
    margin-right: .5rem !important
}

a {
    color: var(--bs-link-color);
    text-decoration: unset !important;
}

#sidebar-menu>ul>li>a.router-link-active {
    background: #f5f5f5!important;
    border-left: 3px solid #00b19d;
}

.text-right {
    text-align: right!important;
}

.form-group {
    margin-bottom: 1rem;
}

.row-login {
    margin-right: 100px !important;
    margin-left: 100px !important;
}

.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check .form-check-input {
    float: left;
    margin-left: 0 !important;
}

.modal-open .modal-backdrop {
    opacity: 0.5;
}

.paginate_count {
    width: 120px !important;
}

.list-item {
    border: none;
    background-color: transparent;
}

.sort_number {
    font-size: 0.7rem;
    float: clear;
}

.word-break-keep {
    word-break: keep-all;
    vertical-align: middle;
}

.green-rflocus {
    color: #fff;
    background-color: #2db19c !important;
    border-color: #2db19c !important;
}

.green-rflocus:hover {
    color: #fff;
    background-color: #5a6268 !important;
    border-color: #545b62 !important;
}

.modal .modal-xxl {
    max-width: 1400px;
    width: 1400px;
}

.headerSettingModal {
    margin-bottom: 1em;
}

.form-check-input:checked~.custom-control-label::before {
    color: #1abc9c !important;
    border-color: #1abc9c !important;
    background-color: #1abc9c !important;
}

.topnav {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    background-color: #2c3e50;
}

/* Style the links inside the navigation bar */
.topnav>* {
    float: left;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav :hover {
    background-color: #ddd;
    color: black;
}

.pagination-end {
    color: grey;
}

.padding--dashboard-table {
    padding-bottom: 20px;
}

.dashboard-table {
    height: 90%;
}

.table {
    --bs-table-striped-bg: rgb(255 255 255 / 0%) !important;
}

.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
    background-color: rgba(0, 139, 139, .1)
}


.table> :not(caption)>*>* {
    padding: 0.75rem !important;
}

.float-right {
    float: right !important
}

.border {
    border: 1px solid #dee2e6 !important
}

.table-bordered {
    border: 1px solid #dee2e6 !important
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #dee2e6 !important
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px !important
}

.table {
    --bs-table-color: #4c5667 !important;
}

.dropdown-menu>li>a {
    display: block;
    padding: 10px 20px !important;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333333;
    white-space: nowrap;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
    color: #fff;
    text-decoration: none;
    background-color: #337ab7;
    outline: 0;
}

.dropdown-menu>.disabled>a,
.dropdown-menu>.disabled>a:hover,
.dropdown-menu>.disabled>a:focus {
    color: #777777;
}

.dropdown-menu>.disabled>a:hover,
.dropdown-menu>.disabled>a:focus {
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.open>.dropdown-menu {
    display: block;

}

.open>a {
    outline: 0;
}


.dropdown {
    position: fixed !important;
    max-height: 300px !important;
   
    z-index: 999 !important;
}

.dropdown-menu> .list-item {
    padding: 5rem 1.25rem;
}
.ml-2{
  margin-left: 0.5rem !important;
}
</style>
