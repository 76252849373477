<template>
<!-- <vue-bootstrap-typeahead v-model="query" :data="options" :serializer="(item) => item.itemCode + '\n' + item.name" @hit="emitOptionValue" placeholder="品目入力・検索" ref="typeahead">

    <template slot="suggestion" slot-scope="{ data, htmlText }">
        <div class="d-flex align-items-center">
            <span v-html="htmlText.split('\n').join('<br>')"></span>
        </div>
    </template>
</vue-bootstrap-typeahead> -->
<b-form-input :id="'input' + index" v-model="inputModel" class="form-control" type="text" placeholder="品目入力・検索" autocomplete="off" />
<Typeahead v-model="model" :target="'#input' + index" :async-function="getItems" item-key="itemCode" >
    <template #item="{ items, activeIndex, select, highlight }">
      <li
        v-for="(item, index) in items"
        :key="item.id"
        :class="{ active: activeIndex === index }"
      >
        <a role="button" class="list-item" @click="select(item)">
          <div>{{item.itemCode}}</div>
          <div>{{item.name}}</div>
        </a>
      </li>
    </template>
</Typeahead>
</template>

<script>
import * as queries from "../graphql/locus_queries";
import AwsApi from "../Api"
import {
    Alert,
    Typeahead
} from 'uiv';
export default {
    components: {
        Alert,
        Typeahead
    },
    emits:["itemCodeInput"],
    props: ["modelValue", "index"],
    watch: {
        // When the query value changes, fetch new results from
        // the API - in practice this action should be debounced
        model(newValue) {
            this.$emit("itemCodeInput", newValue);
        },
    },
    data() {
        return {
            model: "",
            inputModel: "",
            newvalue: "",
            options: [],
            init: true,
            limit: 1000,
            offset: 0,
        };
    },
    mounted() {
        if (this.modelValue) {
            this.inputModel = this.modelValue.itemCode;
        }
    },
    methods: {
        getItems(newQuery, done) {
            if (newQuery === undefined || newQuery === "") {
                return;
            }

            const myInit = {
                limit: this.limit,
                offset: this.offset,
                itemCode: newQuery + "%",
            };

            AwsApi.graphql({
                query: queries.getItemMasterList,
                variables: {
                    conditions: JSON.stringify(myInit),
                },
            }, this).then(response => {
                if (response === undefined) {
                    return
                }

                const convertData = response.data.getItemMasterList.map(item => {
                    var jsonProperties = {}
                    try {
                        jsonProperties = JSON.parse(item)
                    } catch (error) {}
                    return jsonProperties
                })

                this.options = convertData.map((item) => ({
                    ...item,
                    id: item.itemCode
                }));
                done(this.options)

            });

        },
    },
};
</script>
