<template>
<section>
    <div class="row">
        <div class="col-12">
            <div class="card-box">

                <div>
                    <b-input-group class="paginate_count" size="sm" prepend="表示" style="float:left">
                        <b-form-input class="align-middle" v-model="paginateCount">
                        </b-form-input>
                    </b-input-group>
                    <b-input-group v-if="this.title === 'Stocks'" class="paginate_count" size="sm" prepend="合計" style="float:right">
                        <b-form-input class="align-middle" :modelValue="stockCount" disabled>
                        </b-form-input>
                    </b-input-group>

                </div>

                <br>
                <br>

                <b-table class="tablesaw" :busy="loading" bordered :items="items" :fields="fields" striped responsive :select-head="false" :selectable="selectable" @rowSelected="$emit('row-selected', $event)" @rowUnselected="$emit('un-row-selected', $event)" @headClicked="sortChange">


                    <template v-slot:head()="data">
                        <div style="white-space: nowrap">
                            <span>{{ data.label }}</span>
                            <i-bi-caret-down-fill v-if="fields.find(item=> item.label === data.label) === undefined || fields.find(item=> item.label === data.label).sortDirection === undefined" style="visibility: hidden" />
                            <i-bi-caret-up-fill v-else-if="fields.find(item=> item.label === data.label).sortDirection === 'asc'" />
                            <i-bi-caret-down-fill v-else-if="fields.find(item=> item.label === data.label).sortDirection === 'desc'" />
                        </div>

                    </template>

                    <template v-slot:cell(Job.id)="row">
                        <span :class="rowClickAble ? 'number' : null" @click="setModalCreate(row.item)">
                            {{row.item['Job.id']}}
                        </span>
                    </template>

                    <template v-slot:cell(COUNT(JobEpc.epc))="row" class="cursor">
                        <template v-if="row.item['JobDetail.expectAmount'] !== undefined && rowClickAble">
                            <span :class="row.value < row.item['JobDetail.expectAmount'] ? 'notenough' : (row.value > row.item['JobDetail.expectAmount'] ? 'overstock':'number')" @click="setModalItem(row.item)">
                                {{row.value}}
                            </span>
                        </template>
                        <template v-else-if="row.item['SUM(JobDetail.expectAmount)'] !== undefined && rowClickAble">
                            <span :class="row.value < row.item['SUM(JobDetail.expectAmount)'] ? 'notenough' : (row.value > row.item['SUM(JobDetail.expectAmount)'] ? 'overstock':'number')" @click="setModalItem(row.item)">
                                {{row.value}}
                            </span>
                        </template>
                        <template v-else>
                            <span :class="rowClickAble ? 'number' : null" @click="setModalItem(row.item)">
                                {{row.value}}
                            </span>
                        </template>
                    </template>

                    <template v-slot:cell(JobDetail.expectAmount)="row" class="cursor">
                        <template v-if="row.item['COUNT(JobEpc.epc)'] !== undefined && rowClickAble">
                            <span :class="row.value < row.item['COUNT(JobEpc.epc)'] ? 'notenough1' : (row.value > row.item['COUNT(JobEpc.epc)'] ? 'overstock1':'number1')" >
                                {{row.value}}
                            </span>
                        </template>
                        <template v-else>
                            <span :class="rowClickAble ? 'number1' : null">
                                {{row.value}}
                            </span>
                        </template>
                    </template>

                    <template v-slot:cell(SUM(JobDetail.expectAmount))="row" class="cursor">
                        <template v-if="row.item['COUNT(JobEpc.epc)'] !== undefined && rowClickAble">
                            <span :class="row.value < row.item['COUNT(JobEpc.epc)'] ? 'notenough1' : (row.value > row.item['COUNT(JobEpc.epc)'] ? 'overstock1':'number1')" >
                                {{row.value}}
                            </span>
                        </template>
                        <template v-else>
                            <span :class="rowClickAble ? 'number1' : null">
                                {{row.value}}
                            </span>
                        </template>
                    </template>

                    <template v-slot:cell(realAmount)="row" class="cursor">
                        <template v-if="row.item['expectAmount'] !== undefined && rowClickAble">
                            <span :class="row.value < row.item['expectAmount'] ? 'notenough' : (row.value > row.item['expectAmount'] ? 'overstock':'number')" @click="setModalItem(row.item)">
                                {{row.value}}
                            </span>
                        </template>
                        <template v-else>
                            <span :class="rowClickAble ? 'number' : null" @click="setModalItem(row.item)">
                                {{row.value}}
                            </span>
                        </template>
                    </template>

                    <template v-slot:cell(expectAmount)="row" class="cursor">
                        <template v-if="row.item['realAmount'] !== undefined && rowClickAble">
                            <span :class="row.value < row.item['realAmount'] ? 'notenough1' : (row.value > row.item['realAmount'] ? 'overstock1':'number1')" >
                                {{row.value}}
                            </span>
                        </template>
                        <template v-else>
                            <span :class="rowClickAble ? 'number1' : null">
                                {{row.value}}
                            </span>
                        </template>
                    </template>

                    <template v-slot:cell(stockAmount)="row" class="cursor">
                        <span :class="rowClickAble ? 'number' : null" @click="setModalItem(row.item)">
                            {{row.value}}
                        </span>
                    </template>

                    <template v-slot:cell(temiPath)="row" class="cursor">
                        <ButtonAdd v-if="row.item.hasRobotLogFlag" class="create_label"  @click="showRobotPathFun(row.item['Job.id'])">Replay</ButtonAdd>
                    </template>

                    <template v-slot:cell()="row">
                        <template v-if="row.item['NUMBER'] === row.field.key">
                            <span :class="rowClickAble ? 'number' : null" @click="setModalItem(row.item)">
                                {{row.value}}
                            </span>
                        </template>
                        <template v-else-if="row.field.key.indexOf('BARCODE(') !== -1">
                            <Barcode :text="row.item[row.field.key.replace('BARCODE(','').replace(')','')]" :index="row.item[row.field.key.replace('BARCODE(','').replace(')','')] + row.index"></Barcode>
                        </template>
                        <template v-else>
                            <span class="cursor">{{ row.value }}</span>
                        </template>
                    </template>

                </b-table>

                <div class="d-flex justify-content-center align-content-center mt-4">
                    <slot name="paginate"></slot>
                </div>

                <slot name="button"></slot>

            </div>
        </div>
    </div>

</section>
</template>

<script>
import Paginate from '../ThePaginate.vue'
import moment from 'moment';
import {
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BPagination,
    BTable
} from "bootstrap-vue-next";
import Barcode from "../barcode/Barcode.vue"

export default {
    props: ['elements', 'loading', 'headers', 'paginate', 'value', 'title', 'sort', 'selectable', 'stockCount', 'rowClickAble'],
    components: {
        Paginate,
        BTable,
        BInputGroup,
        BFormInput,
        BInputGroupText,
        Barcode
    },
    data() {
        return {
            paginateCount: 0,
        }
    },
    filters: {
        moment: function (date) {
            return date ? moment(date).format('YYYY/MM/DD HH:mm:ss') : '';
        },
        momentDay: function (date) {
            return date ? moment(date).format('YYYY/MM/DD') : '';
        }
    },
    watch: {
        paginateCount: function () {
            this.$emit('updatePaginateCount', this.paginateCount)
        },
    },
    mounted() {
        this.paginateCount = this.value
    },

    computed: {
        items(){
            return this.elements.map((item) =>{
                var color = {}
                if(item.colorFlag === 1){
                    color = {_rowVariant: 'bg-warning'}
                } else if(item.colorFlag === 2){
                    color = {_rowVariant: 'bg-danger'}
                }
                return {
                    ...item,
                    ...color
                }
            })
        },
        fields() {
            let headers = this.headers
                .map((header) => {
                    if (header.source.includes(" as ")) {
                        header.source = header.source.split(" as ")[1]
                    }

                    let index = 0;
                    if (this.sort.map(sort => sort.source).includes(header.source)) {

                        const direction = this.sort.find((item, i) => {
                            index = i;
                            return item.source === header.source;
                        }).direction === 'asc' ? 'asc' : 'desc';
                        return {
                            id: header.id,
                            key: header.source,
                            name: header.name,
                            index: index,
                            direction: direction,
                            class: ['sorting', "word-break-keep", direction],
                        }
                    } else {

                        return {
                            id: header.id,
                            key: header.source,
                            name: header.name,
                            class: ['sorting', "word-break-keep"],
                        }
                    }
                })
                .map((item) => {
                    const index = item.index >= 0 ? item.index + 1 : '';
                    return {
                        key: item.key,
                        label: `${item.name}`,
                        thClass: item.class,
                        sortDirection: item.direction,
                        thAttr: {
                            index: index
                        }
                    }
                });
            return headers;
        }
    },
    methods: {
        setModalItem(item) {
            this.$emit('setModalItem', item)
        },
        setModalCreate(item) {
            this.$emit('setModalCreate', item);
        },
        updatePaginateCount(value) {
            this.$emit('updatePaginateCount', value)
        },
        sortChange(sort) {
            this.$emit('sortChange', sort)
        },
        showRobotPathFun(jobId) {
            this.$emit('showRobotPathFun', jobId)
        }
    }
}
</script>

<style>
/* Busy table styling */
table.b-table[aria-busy='true'] td {
    opacity: 0.7;
}

.sorting {
    cursor: pointer;
    user-select: none
}

.inventoryDeleteItem {
    width: 50px;
}

.inventoryDeleteItem.custom-control {
    padding-left: 2.5rem;
}

.notenough {
    color: red;
    cursor: pointer;
}

.overstock {
    color: #e3c23f;
    cursor: pointer;
}

.number {
    color: #007bff;
    cursor: pointer;
}

.notenough1 {
    color: red;
}

.overstock1 {
    color: #e3c23f;
}

.number1 {
    color: #007bff;
}
.table-bg-danger {
    background-color: #FF8B7B !important
}
.table-bg-warning {
    background-color: #FFCF71 !important
}
.table-bg-danger:nth-child(even)>td {
    --bs-table-color: #4c5667   !important;
    background-color: #FF8B7B !important
}
.table-bg-warning:nth-child(even)>td {
    --bs-table-color: #4c5667   !important;
    background-color: #FFCF71 !important
}
.table-bg-danger:nth-child(odd)>td {
    color: #4c5667   !important;
}
.table-bg-warning:nth-child(odd)>td {
    color: #4c5667   !important;
}
</style>
