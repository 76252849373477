<template>
<img :id="'barcode' + index" src="" alt="" width="170"/>
</template>

<script>
import bwipjs from "bwip-js";
export default {
    props: ['text', 'index'],
    updated: function () {
        this.createBarcode()
    },
    mounted() {
        this.createBarcode()
    },
    methods: {
        createBarcode() {
            // const barcodeElement = document.getElementById('barcode' + this.index);
            // JsBarcode(barcodeElement, this.text, {
            //     height: 30
            // });
            let canvas = document.createElement("canvas");
            try {
                bwipjs.toCanvas(canvas, {
                    bcid: "code128", // バーコード規格（必須）
                    text: this.text, // バーコードに持たせたいテキストデータ（必須）
                    scale: 2, // 生成画像の拡大率（任意）
                    height: 6, // 生成画像の高さをインチで指定（任意）
                    includetext: true, // バーコードの元のテキストデータを画像の下に表示するか（任意）
                    textxalign: "center", // 生成画像におけるバーコードの位置を指定（任意）
                });
                document.getElementById('barcode' + this.index).src = canvas.toDataURL();
            } catch (e) {
                console.error(e);
                return;
            }
        }
    }
}
</script>
