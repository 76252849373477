<template>
<!-- Page-Title -->
<div>
    <div class="row">
        <div class="col-12">
            <div style="position: relative" class="card-box">
                <h4 class="header-title m-t-0" :title="title">{{title}}</h4>
                <slot></slot>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    props: ['title']
}
</script>
