<template>
<!-- 検索タイプ(inputType): text=0,list=1,date=2,query=3,4=datetime -->
<div class="form-group col-auto">
    <label>{{item.name}}</label>
    <b-form-input v-if="item.inputType === 0" v-model="value0"></b-form-input>

    <!-- <app-select v-if="inputType === 1 || inputType === 3" :options="options" v-model="value"></app-select>
         -->
    <div v-if="item.inputType === 2" class="input-group">
        <VueDatePicker class="datepicker_border" v-model="value0" placeholder="Select date" :enable-time-picker="false" :format="format"></VueDatePicker>
    </div>
    <div v-if="item.inputType === 4" class="input-group">
        <VueDatePicker class="datepicker_border" v-model="value1" :enable-time-picker="false" :format="format"></VueDatePicker>
        <VueDatePicker class="datepicker_border" style="width:110px ;margin-left:10px" v-model="value2" time-picker placeholder="00:00"></VueDatePicker>
    </div>
    <div v-if="item.inputType === 5" class="input-group">
        <b-form-checkbox
            id="checkbox-1"
            v-model="value0"
            name="checkbox-1"     
    ></b-form-checkbox>
    </div>
</div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import TheSelect from '../TheSelect.vue'
export default {
    components: {
        'app-select': TheSelect,
        VueDatePicker
    },
    emits:["updateValue"],
    mounted() {
        this.updatePage(this.modelValue)
        this.$watch(
            'value0', (newValue, oldValue) => {
                this.$emit("updateValue", newValue)
            }
        )
        this.$watch(
            'value1', (newValue, oldValue) => {
                if (newValue === undefined) {
                    return
                }
                let date
                let time
                if (Object.prototype.toString.call(newValue) === '[object Date]') {
                    date = this.format(newValue)
                } else {
                    date = newValue
                }
                time = this.timeFormat(this.value2)
                if (time === undefined || time === null || time.length === 0) {
                    date += " 00:00"
                } else {
                    date += " " + time
                }
                this.$emit("updateValue", date)
            }
        )
        this.$watch(
            'value2', (newValue, oldValue) => {
                let time = this.timeFormat(newValue)
                let date = this.value1 + " " + time
                this.$emit("updateValue", date)

            }
        )

    },
    watch: {
        modelValue: {
            handler(newVal, oldVal) {
                this.updatePage(newVal)
            },
            deep: true
        },
    },
    props: ["item", "modelValue"],
    // emits: ['changed-event'],
    data() {
        return {
            value0: null,
            value1: null,
            value2: null,
            options: [],
        }
    },

    methods: {
        onValueChanged() {

        },
        updatePage(newVal) {
            if (newVal && newVal.length > 0) {
                if (this.item.inputType == 4) {
                    let datas = newVal.split(" ")
                    this.value1 = datas[0]
                    let times = datas[1].split(":")
                    if (times.length === 2) {
                        this.value2 = {
                            hours: times[0],
                            minutes: times[1]
                        }
                    }
                } else {
                    this.value0 = newVal
                }
            }
        },
        format(date) {
            if (date === undefined || date === null) {
                return undefined
            }
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${year}/${this.prefix(month)}/${this.prefix(day)}`
        },
        timeFormat(time) {
            if (time === undefined || time === null) {
                return null
            }
            return `${this.prefix(time.hours)}:${this.prefix(time.minutes)}`
        },
        prefix(str) {
            var len = str.toString().length;
            while (len < 2) {
                str = "0" + str;
                len++;
            }
            return str;
        }
    }
}
</script>
