<template>

    <b-button 
           class="btn btn-primary btn-rounded w-md my-3"
           @click="$emit('setModalCreate')">
        新規
    </b-button>

</template>

<script>
import {
    BButton
} from "bootstrap-vue-next";
    export default {
        components: {
            BButton
        },
        props: ['modalCreateId'],
        emits: ['setModalCreate'],
    }
</script>

<style scoped>

</style>
