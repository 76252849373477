<template>
    <b-form-select
            class="form-control"
            :options="options"
            :value="value"
            @change="emitOptionValue"
    >
  
    </b-form-select>
</template>
<script>

    import {BFormSelect} from "bootstrap-vue-next";

    export default {
        components: {
            BFormSelect
        },
        props: ['options', 'value'],
        methods: {
            emitOptionValue(value) {
                this.$emit('input', value);
            }
        }
    }
</script>
