<template>
<div>
    <div class="topbar" style="margin-bottom: 2rem; position: static">
        <!-- LOGO -->
        <div class="topbar-left">
            <div class="text-center">
                <a class="logo">
                    <i class="ti-cloud"></i>
                    <span>Locus Mapping</span>
                </a>
            </div>
        </div>
        <nav class="navbar-custom"></nav>
    </div>
    <main class="py-4">
        <div class="container">
            <div class="row justify-content-center row-login" >
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-header">Login</div>

                        <div class="card-body">
                            <div class="form-group row">
                                <label for="email" class="
                                            col-md-4 col-form-label
                                            text-right
                                        ">E-Mail Address</label>

                                <div class="col-md-6">
                                    <input id="email" class="form-control" :class="{ 'is-invalid': checkUser }" v-model="username" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password" class="
                                            col-md-4 col-form-label
                                            text-right
                                        ">Password</label>

                                <div class="col-md-6">
                                    <input id="password" type="password" name="password" v-model="password" :class="{ 'is-invalid': checkUser }" class="form-control" />
                                    <div class="invalid-feedback">
                                        <strong>{{errorText}}</strong>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-0" v-if="!ipCheck">
                                <div class="col-md-8 offset-md-4">
                                    <div class="ip-error">
                                        <strong>Access denied.</strong>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-0">
                                <div class="col-md-8 offset-md-4">
                                    <button type="submit" class="btn btn-primary" @click="login">
                                        Login
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
</template>

<script>
import {
    Auth,
    API
} from "aws-amplify";
import global from "../Global.vue";
import * as queries from "../graphql/locus_queries";

export default {
    name: "LoginView",
    data() {
        return {
            username: "",
            password: "",
            checkUser: false,
            ipCheck: true,
            clickLoginCheck:false,
            errorText: "Incorrect username or password.",

            loginUserName: "",
            loginTenantId: "",
            menus:[]
        };
    },
    methods: {
        async login() {
            if(this.clickLoginCheck){
               return 
            }
            this.clickLoginCheck = true
            try {
                var user = await Auth.signIn(this.username, this.password);
                if (user) {

                    const ipCheckResponse = await API.graphql({
                        query: queries.checkIp
                    });
                    if (ipCheckResponse.data.checkIp === false) {
                        this.ipCheck = false
                        this.clickLoginCheck = false
                    } else {
                        this.ipCheck = true
                        this.checkUser = false;
                        localStorage.setItem(global.userName, user.username);
                        this.loginUserName = user.username
                        const tenantIds = user.signInUserSession.idToken.payload["cognito:groups"]
                        const tenantId = tenantIds.find(item => item != "user");
                        if(tenantId == "Admins"){
                            localStorage.setItem(global.userName, user.username);
                            this.$router.push("/admin")
                            return
                        }
                        if (user.username.indexOf('guest') !== -1) {
                            localStorage.setItem(global.isGuest, "true");
                            localStorage.setItem(global.localUserName, this.username);
                            localStorage.setItem(global.localPassword, this.password);
                        } else {
                            localStorage.setItem(global.isGuest, "false");
                            localStorage.setItem(global.localUserName, "");
                            localStorage.setItem(global.localPassword, "");
                        }
                        localStorage.setItem(global.tenantId, tenantId);
                        this.loginTenantId = tenantId
                        await this.getMenu(tenantId)
                        this.clickLoginCheck = false
                    }

                } else {
                    this.checkUser = true;
                    this.clickLoginCheck = false
                }
            } catch (error) {
                this.checkUser = true;
                this.clickLoginCheck = false
                this.errorText = error.message
                console.log("error signing up:", error);
            }
        },
        async getMenu(tenantId) {
            const response = await API.graphql({
                query: queries.listMenus,
                variables: {
                    tenantId: tenantId
                }
            });

            this.menus = response.data.listMenus.items.filter(item => {
                return item.showSide == null || item.showSide != global.showAPP
            }).sort(function (a, b) {
                return a.showOrder - b.showOrder
            });
            //UserManage
            if(global.userManageFlag){
                if(this.loginTenantId !== this.loginUserName) {
                    await this.getFunctionIdOption()
                }
            }
            if (this.menus.length !== 0 && this.menus[0].functionId !== undefined) {
                if (this.menus[0].functionId === global.functionIdMASTER) {
                    this.$router.push(this.menus[0].functionId + "/" + this.menus[0].id + "/0");
                } else {
                    this.$router.push(this.menus[0].functionId + "/" + this.menus[0].id);
                }
            } else {
                this.$router.push("/" + global.functionIdSETTINGS + "/-1")
            }

        },
        async getFunctionIdOption() {
            const response = await API.graphql({
                query: queries.getUserManage,
                variables: {
                    tenantId: this.loginTenantId,
                    username: this.loginUserName,
                }
            });

            if (response === undefined || response.data.getUserManage === null) {
                this.menus = []
                return
            }
            this.menus = this.menus.filter(item => response.data.getUserManage.menuId.includes(item.id))

        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.ip-error {
    color: #dc3545;
    padding-bottom: 10px;
}
</style>
