<template>
    <b-pagination
            align="center"
            :total-rows="paginate.total"
            :per-page="Number(paginate.per_page)"
            v-model="paginate.current_page"
            @page-click="updatePage">
    </b-pagination>
</template>
<script>

    import {BPagination} from "bootstrap-vue-next";

    export default {
        props: ['paginate'],
        components: {
            BPagination
        },
        methods: {
            updatePage(BvEvent ,page) {
                this.$emit('updatePage', page)
            }
        }
    }
</script>
