<template>
<section>
    <div class="row">
        <div class="col-12">
            <div class="card-box">
                <span style="
                    font-weight: 600;
                    display: inline-block;
                    padding: 1rem;
                    padding-left: 0.5rem;
                    cursor: pointer;
                " @click="$emit('backToLocationPage')">
            &lt;&lt; back</span> 
                <br>

                <b-table class="tablesaw" :busy="loading" :items="items" :fields="fields" striped bordered @headClicked="sortChange" responsive :select-head="false">
                    <template v-slot:head()="data">
                        <div style="white-space: nowrap">
                            <span>{{ data.label}}</span>
                            <i-bi-caret-down-fill v-if="fields.find(item=> item.label === data.label) === undefined || fields.find(item=> item.label === data.label).sortDirection === undefined" style="visibility: hidden" />
                            <i-bi-caret-up-fill v-else-if="fields.find(item=> item.label === data.label).sortDirection === 'asc'" />
                            <i-bi-caret-down-fill v-else-if="fields.find(item=> item.label === data.label).sortDirection === 'desc'" />
                        </div>
                    </template>

                </b-table>
                <b-pagination align="center" :total-rows="paginate.total" :per-page="Number(paginate.per_page)" v-model="paginate.current_page" @page-click="updatePage" class="my-0" />


            </div>
        </div>
    </div>

</section>
</template>

<script>

import {
    BTable, BPagination
} from "bootstrap-vue-next";

export default {
    props: ['headers', 'loading', 'items', 'sort', 'title', 'paginate'],
    components: {
        BTable,
        BPagination
    },
    data() {
        return {
            customOrder: ["ItemMaster.p1","ItemMaster.p2","ItemMaster.p3","ItemMaster.p4","expectAmount","realAmount"],
        }
    },
    computed: {
        fields() {
            let headers = this.headers
                .map((header) => {
                    if (header.source.includes(" as ")) {
                        header.source = header.source.split(" as ")[1]
                    }
                    
                    let index = 0;
                    if (this.sort.map(sort => sort.source).includes(header.source)) {

                        const direction = this.sort.find((item, i) => {
                            index = i;
                            return item.source === header.source;
                        }).direction === 'asc' ? 'asc' : 'desc';
                        return {
                            id: header.id,
                            key: header.source,
                            name: header.name,
                            index: index,
                            direction: direction,
                            class: ['sorting', "word-break-keep", direction],
                        }
                    } else {

                        return {
                            id: header.id,
                            key: header.source,
                            name: header.name,
                            class: ['sorting', "word-break-keep"],
                        }
                    }
                })
                .map((item) => {
                    const index = item.index >= 0 ? item.index + 1 : '';
                    return {
                        key: item.key,
                        label: `${item.name}`,
                        thClass: item.class,
                        sortDirection: item.direction === undefined ? '' :item.direction,
                        thAttr: {
                            index: index
                        }
                    }
                });
                 
              var sort = headers.sort((a, b) => {
                    return this.customOrder.indexOf(a.source) - this.customOrder.indexOf(b.source);
                });
            return sort
        }
    },
    methods: {
        sortChange(sort) {
            this.$emit('sortChange', sort)
        },
        updatePage(BvEvent, page) {
            this.page = page;
            this.$emit('updatePage', page);
        },
    }
}
</script>

<style>
.table-yellow {
    background-color: yellow;
}

.table-grey {
    background-color: lightgrey;
}

.table-white {
    background-color: white;
}
</style>
