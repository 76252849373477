import { v1 as uuidv1 } from 'uuid';

class Warehouse {
    constructor(warehouse) {
        this.id                       = warehouse.id
        this.name                     = warehouse.name
        this.remarks                  = warehouse.remarks
        this.width                    = parseInt(warehouse.width === null ? 1 : warehouse.width)                 // width of the image in pixels
        this.height                   = parseInt(warehouse.height === null ? 1 : warehouse.height)                // height of the image in pixels
        this.real_width               = parseFloat(warehouse.real_width === null ? 1 : warehouse.real_width)            // width of the bounding box in meters
        this.real_height              = parseFloat(warehouse.real_height === null ? 1 : warehouse.real_height)           // height of the bounding box in meters
        this.border_left_x            = 0         // 1st x coordinate of bounding box in %
        this.border_left_y            = 0         // 1st y coordinate of bounding box in %
        this.border_right_x           = 0        // 2nd x coordinate of bounding box in %
        this.border_right_y           = 0        // 2nd x coordinate of bounding box in %
        this.default_shelf_height     = parseFloat(warehouse.default_shelf_height === null ? 1 : warehouse.default_shelf_height)
        this.default_shelf_width      = parseFloat(warehouse.default_shelf_width === null ? 1 : warehouse.default_shelf_width)
        this.upload                   = warehouse.upload                // the base image

        this.robot_home_x             = warehouse.robot_home_x          
        this.robot_home_y             = warehouse.robot_home_y
        this.robot_stop_meters        = warehouse.robot_stop_meters
        this.robot_stop_seconds       = warehouse.robot_stop_seconds
        this.robot_distance           = warehouse.robot_distance
        this.robot_orientation        = warehouse.robot_orientation

        this.shelf_transparency        = warehouse.shelf_transparency

        this._user_border_left_x      = 0
        this._user_border_left_y      = 0
        this._user_border_right_x     = 0
        this._user_border_right_y     = 0

        this._scale_fit_x             = 1
        this._scale_fit_y             = 1
        this._scale_x                 = 1
        this._scale_y                 = 1

        this._on_change               = () => {}

        this._default_shelf_width     = this.default_shelf_width
        this._default_shelf_height    = this.default_shelf_height

        this.pxToMeter_x              = this.width * (this.border_left_x + this.border_right_x) / this.real_width
        this.pxToMeter_y              = this.height * (this.border_left_y + this.border_right_y) / this.real_height
        this.meterToPx_x              = this.real_width / (this.width * (this.border_left_x + this.border_right_x))
        this.meterToPx_y              = this.real_height / (this.height * (this.border_left_y + this.border_right_y))

        this.multicolorSelect         = false;

        if (warehouse.locations === undefined)
            warehouse.locations = []
        if (warehouse.tags === undefined)
            warehouse.tags = []

        this.locations                = warehouse.locations.map(location=>{
            if (!(location instanceof Location))
                location = new Location(location, location.id !== undefined && location.id !== 0 ? `saved`:`new`)
            location.warehouse = this
            return location
        })
        this.tags                     = warehouse.tags.map(tag=>{
            if (!(tag instanceof Tag))
                tag = new Tag(tag, tag.id !== undefined && tag.id !== 0 ? `saved`:`new`)
            tag.warehouse = this
            let target_shelf = this.locations.filter(_=>_.id == tag.location_id)[0]
            if (target_shelf) {
                tag.location = target_shelf
            } 
            return tag
        })
        this._checkpoints             = [] // used for saving change history
        
        this.location_tag_filter = warehouse.location_tag_filter
        this.calculateScale()
    }

    registerChangeCallback(callback)
    {
        this._on_change = callback
    }

    calculateScale() {
        let left_x = this.border_left_x + this._user_border_left_x
        let left_y = this.border_left_y + this._user_border_left_y
        let right_x = this.border_right_x + this._user_border_right_x
        let right_y = this.border_right_y + this._user_border_right_y

        this._scale_fit_x             = this.real_width * Math.max(1 - left_x - right_x, 0.1) // sanity check
        this._scale_fit_y             = this.real_height * Math.max(1 - left_y - right_y, 0.1) // sanity check
        this._scale_x                 = this._scale_fit_x / this.real_width
        this._scale_y                 = this._scale_fit_y / this.real_height
    }

    update(delta, type) {
        Checkpoint.update(this, delta, type)
        this.calculateScale()
    }

    diff(diff) {
        let _border_deleted = {stroke: '#af3636', line_width: 1}
        let _border_changed = {stroke: '#9b5c3f', line_width: 1}
        let _border_created = {stroke: '#587b23', line_width: 1}
        let _border_unchanged = {stroke: '#303037', line_width: 1}
        let _border_pending = {stroke: '#587b23', line_width: 1}
        let _fill_deleted = '#ff5050'
        let _fill_changed = '#ff9966'
        let _fill_created = '#99ff66'
        let _fill_unchanged = '#797986'
        let _fill_pending = '#99ff66'

        if (diff === null) {
            this.locations.map(_ => {
                _._border = null
                _.fill = null
            })
            return
        }
        diff.locations.map(_ => _._border = null)

        this.locations.map(base_loc => {
            let locs = diff.locations.filter(_=>_.id === base_loc.id)
            if (locs.length === 0) {
                if (base_loc._status === 'new' || base_loc._status === 'change') {
                    base_loc._border = _border_pending
                    base_loc.fill = _fill_pending
                } else {
                    base_loc._border = _border_created
                    base_loc.fill = _fill_created
                }
            } else {
                let diff_loc = locs[0]
                if (base_loc._status === 'delete') {
                    diff_loc._border = _border_created
                    diff_loc.fill = _fill_created
                } else if (!base_loc.eq(diff_loc)) {
                    base_loc._border = _border_changed
                    diff_loc._border = _border_changed
                    base_loc.fill = _fill_changed
                    diff_loc.fill = _fill_changed
                } else {
                    diff_loc._border = _border_unchanged
                    diff_loc.fill = _fill_unchanged
                    base_loc._border = _border_unchanged
                    base_loc.fill = _fill_unchanged
                }
            }
        })
        diff.locations.filter(_=>_._border === null).map(_=> {
            _._border = _border_deleted
            _.fill = _fill_deleted
        })
    }

    makeLocationTag(location_tag_filter, label){
        if(location_tag_filter !== undefined && location_tag_filter !== null && location_tag_filter.length){
            const label_hex = label.toString(16).toUpperCase()
            const pad_count = 24 - location_tag_filter.length - label_hex.length
            return location_tag_filter.toUpperCase() + "".padEnd(pad_count, '0') + label_hex
        } else {
            const label_hex = label.toString(16).toUpperCase()
            const pad_count = 24 - label_hex.length
            return "".padEnd(pad_count, '0') + label_hex
        }
    }

    static empty() {
        return new Warehouse({
            id: 0,
            name: 'warehouse',
            remarks: '',
            width: 250,
            height: 180,
            real_width: 25,
            real_height: 18,
            border_left_x: 0.045,
            border_left_y: 0.11,
            border_right_x: 0.05,
            border_right_y: 0.08,
            default_shelf_height: 1,
            default_shelf_width: 1,
            svg: '',
            upload: '/images/demoFactory.svg',
            locations: [
                new Location({
                    id: 0,
                    x: 0,
                    y: 0,
                    width: 1,
                    height: 1,
                    label: 1,
                    locations: [
                        new Tag({
                            id: 0,
                            x: 1,
                            y: 1,
                            label: 1
                        }, 'new')
                    ]
                }, 'new'),
                new Location({
                    id: 0,
                    x: 1,
                    y: 1,
                    width: 1,
                    height: 1,
                    label: 2,
                }, 'saved'),
                new Location({
                    id: 0,
                    x: 2,
                    y: 2,
                    width: 1,
                    height: 1,
                    label: 3,
                }, 'new'),
                new Location({
                    id: 0,
                    x: 3,
                    y: 3,
                    width: 1,
                    height: 1,
                    label: 4,
                }, 'new'),
                new Location({
                    id: 0,
                    x: 4,
                    y: 4,
                    width: 1,
                    height: 1,
                    label: 5,
                }, 'new')
            ],
            tags: [],
        })
    }
}

class Selection {
    // The warehouse stores the checkpoints, which we need for making changes
    constructor (warehouse, objects = []) {
        this.warehouse = warehouse
        this.bounds = {x: 0, y: 0, width: 0, height: 0}
        this.delta = {x: 0, y: 0}
        this.pending_delta = {x: 0, y: 0}
        this.origin = {x: 0, y: 0, touched: false}
        this.offset = {x: 0, y: 0, touched: false}
        this.objects = []

        this._color = null

        console.log(`Selection: Create [${objects.length}]`)
        while (objects.length) {
            this.add(objects.pop())
        }
    }

    get color() {
        return this._color ? this._color : `yellow`
    }

    set color(val) {
        this._color = val
    }


    static get MODE_ADD () { return 0 }
    static get MODE_REMOVE () { return 1 }

    static get RES_OKAY () { return 0 } // response: everything successful
    static get RES_COMMIT_ADD () { return 1 } // response: commit and then add again
    static get RES_COMMIT_NEW () { return 2 } // response: commit and then create a new empty batch first
    static get RES_BAD_OBJECT () { return 3 } // response: the object doesn't support selection
    static get RES_OKAY_NO_CHANGE () { return 4 } // response: the object doesn't support selection

    get has_changes() {
        return this.delta.x !== 0 || this.delta.y !== 0
    }

    // Add an item to the selection
    // If there are pending changes, return false so that the parent system knows it needs to save first
    // Recalculate the bounds of the selection
    // Mark item as selected for visuals
    add(object) {
        console.log(`Selection: Add [${object.x}, ${object.y}, ${object._element_id}]`)
        return this.modify_objects(object, Selection.MODE_ADD)
    }

    // Remove an item from the selection
    // If there are pending changes, return false so that the parent system knows it needs to save first
    // Recalculate the bounds of the selection
    // Mark item as deselected for visuals
    remove(object) {
        return this.modify_objects(object, Selection.MODE_REMOVE)
    }

    modify_objects(object, mode) {
        if (this.has_changes) return Selection.RES_COMMIT_ADD
        if (this.objects.length && !(this.objects[0] instanceof Location)) return Selection.RES_COMMIT_NEW
        if (object.set_selected === undefined) return Selection.RES_BAD_OBJECT

        switch(mode) {
            case Selection.MODE_ADD:
                // in the case the object already exists
                if (this.objects.indexOf(object) !== -1) return Selection.RES_OKAY_NO_CHANGE
                this.objects.push(object)
                object.set_selected(true)
                break
            case Selection.MODE_REMOVE:
                // in the case the object doesnt exist
                if (this.objects.indexOf(object) === -1) return Selection.RES_OKAY_NO_CHANGE
                this.objects.splice(this.objects.indexOf(object), 1)
                object.set_selected(false)
                break
        }

        this.bounds = Selection.determine_bounds(this.objects)

        return Selection.RES_OKAY
    }

    // Update the drag by comparing the new delta with the origin delta
    // If delta is empty, set it
    // * Recalculate the pending deltas of all objects in the selection
    check(coordinates, force = false) {
        if (!this.origin.touched || force) {
            // console.log(`Selection: Set Origin/Offset`)
            this.origin = {x: coordinates.x - this.delta.x,  y: coordinates.y - this.delta.y,  touched: true}
            this.offset = {x: this.origin.x - this.bounds.x, y: this.origin.y - this.bounds.y, touched: true}

            // console.log(`bounds`,this.bounds.x, this.bounds.y)
            // console.log(`coordinates`,coordinates.x, coordinates.y)
            // console.log(`offset`,this.offset.x, this.offset.y)
        }
    }

    drag(coordinates, force = false) {
        this.check(coordinates, force)

        this.delta = {x: coordinates.x - this.origin.x, y: coordinates.y - this.origin.y}

        Selection.update_delta(this.objects, this.delta)
    }

    // Commit the pending deltas to the history and clear the batch
    commit(shared_batch_id) {
        if (!this.has_changes) return Selection.RES_OKAY

        for (let i = 0; i < this.objects.length; i++) {
            let obj = this.objects[i]
            if (obj instanceof Location)
                obj.update_from_self(shared_batch_id)
            else
                throw new Error('Unsupported object type')
        }

        return Selection.RES_OKAY
    }

    delete(shared_batch_id) {
        for (let i = 0; i < this.objects.length; i++) {
            let obj = this.objects[i]
            if (obj instanceof Location)
                obj.delete(shared_batch_id)
            else
                throw new Error('Unsupported object type')
        }

        return Selection.RES_OKAY
    }

    // Deselect everything so this Selection can be destroyed
    deselectAll() {
        for (let i = 0; i < this.objects.length; i++) {
            let obj = this.objects[i]
            obj._batch_x = 0
            obj._batch_y = 0
            obj.set_selected(false)
        }

        return Selection.RES_OKAY
    }


    // Recalculate the bounds of the selection
    // These are the bounds before any changes
    static determine_bounds(objects) {
        let bounds = {x: objects[0].x, y: objects[0].y, width: objects[0].width, height: objects[0].height}
        for (let i = 1; i < objects.length; i++) {
            let obj = objects[i]
            if (obj.x < bounds.x) {
                bounds.width += bounds.x - obj.x
                bounds.x = obj.x
            }
            if (obj.y < bounds.y) {
                bounds.height += bounds.y - obj.y
                bounds.y = obj.y
            }
            if (obj.x + obj.width > bounds.x + bounds.width) bounds.width = obj.x + obj.width - bounds.x
            if (obj.y + obj.height > bounds.y + bounds.height) bounds.height = obj.y + obj.height - bounds.y
        }
        return bounds
    }

    // Recalculate the pending deltas of all objects in the selection
    static update_delta(objects, delta) {
        for (let i = 0; i < objects.length; i++) {
            let obj = objects[i]
            obj._batch_x = delta.x
            obj._batch_y = delta.y
            let children = obj.children
            Selection.update_delta(children, delta)
        }
    }

}

class Tag {
    constructor (tag, status) {
        if (status === undefined) {
            throw new Error('Tag requires status')
        }
        this._status = status

        this._warehouse = null
        this._location = null
        this._location_id = tag._location_id !== undefined ? tag._location_id : null
        this.location_id = tag.location_id !== undefined ? parseInt(tag.location_id) : null
        this._element_id = uuidv1() + this.location_id
        this.id = parseInt(tag.id)
        this.x = parseFloat(tag.x)
        this.y = parseFloat(tag.y) // keep as int for now
        this.getLocationTag(tag)
        this._offset_x = 0
        this._offset_y = 0

        this._batch = null
        this._batch_x = 0
        this._batch_y = 0
        this._selected = false
    }
    set warehouse(warehouse) {
        this._warehouse = warehouse
    }
    set location(location) {
        //if (shelf === null || shelf === undefined) return
        this._location = location
        if (location) {
            this._location_id = location._element_id
            this.calculateOffset()
        }
    }
    get children() {
        return []
    }
    set_selected(value) {
        this._selected = !!value
    }
    calculateOffset() {
        if (!this._location) {
            console.log('Warning, tag '+this._element_id+' does not have a parent')
            return
        }
        this._offset_x = this.x - this._location.x
        this._offset_y = this.y - this._location.y
    }
    update(delta, type) {
        Checkpoint.update(this, delta, type)
        if (delta.hasOwnProperty('x') || delta.hasOwnProperty('y')) {
            this.calculateOffset()
        }
    }

    getLocationTag(tag){
        if(tag.epc !== undefined && tag.epc !== null && tag.epc.length > 5){
            this.label = parseInt(tag.epc.slice(-5), 16);
        } else if(tag.label !== undefined && tag.label !== null ){
            this.label = tag.label
        }
        
    }
}

class Checkpoint {
    constructor(target, original, delta, operation_type) {
        this.target = target
        this.delta = delta
        this.original = original
        this.operation_type = operation_type
    }

    rollback(history) {
        let changes = Object.keys(this.original)
        let target_type = Checkpoint.targetType(this.target)

        for (let i=0; i<changes.length;i++) {
            if (changes[i].indexOf('_batch_') > -1 && this.operation_type !== `trash`) continue
            this.target[changes[i]] = this.original[changes[i]]
        }

        if (this.delta.hasOwnProperty('_location_id')) {
            // reassociate target<Location> with old shelf

            this.target.location = this.target._warehouse.locations.filter(_=>_._element_id === this.original._location_id)[0]
            console.log('reassociated with old location', this.target.location)
        }

        if (this.delta.hasOwnProperty('_batch_mix') && this.delta._batch_mix) {
            console.log('applying batch mix', this.original._batch_x, this.delta._batch_x)
            // we need to reapply the batch back to the item position
            this.target._batch_x = this.original._batch_x
            this.target._batch_y = this.original._batch_y
        }

        if (this.operation_type === `add`) {
            // there is a null batch item on the history -- it should be the last item
            // it needs to be removed from the history and the created object needs to be set to ignored
            // ignored objects are not sent to the api
            this.target._status = `ignore`

            // shelves create two history items, and the earlier one just needs to be removed from the history
            //if (target_type === `Shelf`) history.pop()
        }

        if (target_type === `Warehouse`) {
            this.target.calculateScale()
        }

    }

    // pass the warehouse _checkpoints and it will rollback the last batch
    static undo(history) {
        if (!history.length) return
        let batch = history[history.length - 1].delta._batch
        // foreach object with the same batch, sequentially rollback
        while (batch !== null && history.length) {
            if (history[history.length - 1].delta._batch !== batch) {
                batch = null
                continue
            }

            let checkpoint = history.pop()
            checkpoint.rollback(history)

            let target_type = Checkpoint.targetType(checkpoint.target)
            let warehouse = null
            if (target_type !== `Warehouse`)
                warehouse = checkpoint.target._warehouse

            if (warehouse instanceof Warehouse)
                warehouse._on_change(checkpoint, true)
        }
    }

    static targetType(target) {
        let target_type = `Location`
        if (target instanceof Tag)
            target_type = `Tag`
        else if (target instanceof Warehouse)
            target_type = `Warehouse`
        return target_type
    }

    static update(target, delta, operation_type) {
        let changes = Object.keys(delta)
        if (delta._status === undefined)
            throw new Error('delta._status is required')
        if (delta._batch === undefined)
            throw new Error('delta._batch is required')
        if (operation_type === undefined)
            throw new Error('operation_type is required')

        let target_type = Checkpoint.targetType(target)

        let checkpoints = null
        let warehouse = null
        if (target_type === `Warehouse`) {
            checkpoints = target._checkpoints
            warehouse = target
        } else {
            checkpoints = target._warehouse._checkpoints
            warehouse = target._warehouse
        }

        let original = {}
        let actual_delta = {}

        for (let i=0; i<changes.length;i++) {
            let new_value = delta[changes[i]]
            if (changes[i] === `_status` && target[changes[i]] === `new`) {
                switch (delta[changes[i]]) {
                    case `change`:
                        new_value = `new`
                        break
                    case `delete`:
                        new_value = `ignore`
                        break
                    case `new`:

                }
            }
            original[changes[i]] = target[changes[i]]   // store a copy of the current value
            actual_delta[changes[i]] = new_value        // store a copy of the true change
            target[changes[i]] = new_value
        }
        // the checkpoint is the true state change, but the logic for sorting out rollbacks is handled at the time of
        // rollback -- we don't care about data transformations at this point
        if (operation_type === `manual` && target_type === `Tag`) {
            // if there is already a checkpoint for this target for this batch, replace that checkpoint instead
            let match = checkpoints.filter(_=> _.delta._batch === delta._batch && _.target._element_id === target._element_id)
            if (match.length === 1) {
                match = match[0]
                match.delta = actual_delta
                console.log('reusing checkpoint', match)
                return
            }
        }
        let new_checkpoint = new Checkpoint(target, original, actual_delta, operation_type)
        checkpoints.push(new_checkpoint)

        if (warehouse instanceof Warehouse)
            warehouse._on_change(new_checkpoint)
    }
}

class Location {
    constructor (location, status) {
        if (status === undefined) {
            throw new Error('Shelf requires status')
        }
        this._status = status
        this._warehouse = null
        this._element_id = uuidv1()
        this.id = parseInt(location.id)
        this.x = parseFloat(location.x)
        this.y = parseFloat(location.y)
        this.width = parseFloat(location.width)
        this.height = parseFloat(location.height)
        this.rotation = location.hasOwnProperty('rotation') ? parseFloat(location.rotation) : 0
        this.label = location.label
        this.number = location.number
        this._item_hover = false
        // this.locations = shelf.hasOwnProperty('locations') ? shelf.locations.map(location => {
        //     location.shelf = this
        //     return location
        // }) : []

        this.scale_x = 1
        this.scale_y = 1

        // this._fill = 'lightgreen'
        this._border = null

        this._batch = null
        this._batch_x = 0
        this._batch_y = 0
        this._batch_w = 0
        this._batch_h = 0
        this._batch_r = 0

        // for handling manual user input of values
        this._user_x = 0
        this._user_y = 0
        this._user_w = 0
        this._user_h = 0
        this._user_r = 0

        this._search_select = false
        this.amountAlertFlag = false
        this.daysAlertFlag = false
        this._selected = false
        this._fill_override = 'lightgreen';
        this._current_search = false
        this._old_search = false
    }

    get _fill() {
        if (!this._warehouse.multicolorSelect || this._fill_override !== 'lightgreen') return this._fill_override;

        return this._warehouse.multicolorSelect(this.label, false)
    }

    set _fill(val) {
        this._fill_override = val;
    }

    get info() {
        // generate semi-user friendly information about this object
        // (x, y) (x2, y2) ((x2-x)/2, (y2-y)/2)
        let x = this.x + this._batch_x
        let y = this.y + this._batch_y
        let x2 = x + this.width + this._batch_w
        let y2 = y + this.height + this._batch_h
        let xC = (x2-x)/2 + x
        let yC = (y2-y)/2 + y

        x = x.toFixed(2)
        y = y.toFixed(2)
        x2 = x2.toFixed(2)
        y2 = y2.toFixed(2)
        xC = xC.toFixed(2)
        yC = yC.toFixed(2)
        return `(`+x+`,`+y+`) (`+x2+`,`+y2+`) (`+xC+`,`+yC+`)`;
    }

    get tags() {
        if (this._warehouse === null) throw new Error('Trying to find tags before attaching warehouse')
        return this._warehouse.tags.filter(_=>_._location === this)
    }

    set warehouse(warehouse) {
        this._warehouse = warehouse
        // this.scale_x = warehouse.meterToPx_y / warehouse.meterToPx_x
        // this.scale_y = warehouse.meterToPx_x / warehouse.meterToPx_y
    }

    set_selected(value) {
        this._selected = !!value
        if (this._selected) {
            // force the item to appear on the top of all other items
            this._warehouse.locations.splice(this._warehouse.locations.indexOf(this), 1)
            this._warehouse.locations.push(this)
        }
    }

    eq(comp) {
        if (this.width !== comp.width || this.height !== comp.height || this.x !== comp.x || this.y !== comp.y || this.rotation !== comp.rotation) {
            return false
        }

        let orderById = (x, y) => {
            if (x.id < y.id) {
                return -1;
            }
            if (x.id > y.id) {
                return 1;
            }
            return 0;
        }
        let A = this.children.sort(orderById).map(_ => ''+_.id+','+_.x +','+ _.y+','+ _.label).join(' ')
        let B = comp.children.sort(orderById).map(_ => ''+_.id+','+_.x +','+ _.y+','+ _.label).join(' ')
        return A === B
    }

    set fill(value) {
        if (value === null) {
            value = 'lightgreen'
        }
        this._fill = value
    }

    get fill() {
        if (this._fill === 'lightgreen') {
            return null
        }
        return this._fill
    }

    delete(shared_batch_id) {
        let delta = {
            _status: `delete`,
            _batch_x: this._batch_x,
            _batch_y: this._batch_y,
            _batch: shared_batch_id
        }

        this._selected = false
        this._batch_x = 0
        this._batch_y = 0

        this.tags.filter(_=>_._status !== `ignore`).map(_=> {
            _._batch_x = 0
            _._batch_y = 0
            _.update(delta,`trash`)
        })

        this.update(delta, `trash`)
    }

    get children() {
        return this.tags
    }

    update_from_self(shared_batch_id) {
        let delta = {
            _status: `change`,
            _batch: shared_batch_id,
            x: this.x + this._batch_x,
            y: this.y + this._batch_y,
        }
        this._batch_x = 0
        this._batch_y = 0

        delta.width = this.width + this._batch_w
        delta.height = this.height + this._batch_h
        this._batch_w = 0
        this._batch_h = 0

        let location_delta = {
            _status: `change`,
            _batch: shared_batch_id,
        }
        this.tags.filter(_=>_._status !== `ignore` && _._status !== `delete`).map(tag => {
            location_delta.x = tag.x + tag._batch_x
            location_delta._batch_x = 0
            location_delta.y = tag.y + tag._batch_y
            location_delta._batch_y = 0
            tag.update(location_delta, `adjust`)
        })

        this.update(delta, `adjust`)
    }

    update(delta, type) {
        Checkpoint.update(this, delta, type)
    }
}

export { Warehouse, Location, Tag, Checkpoint, Selection }
