<template>
<div>
    <b-table striped responsive borderless :fields="header" :items="attributes">
        <template v-slot:cell(delete)="data">
            <b-form-checkbox v-model="data.item.checked"></b-form-checkbox>
        </template>

        <template v-slot:cell(name)="data">
            <template v-if="data.item && data.item.isDisabled">
                <b-form-input v-model="data.item.name" disabled></b-form-input>
            </template>
            <template v-else>
                <b-form-select v-model="data.item.source" :options="nameList">
                </b-form-select>
            </template>
        </template>
        <template v-slot:cell(list)="data">
            <b-form-checkbox v-model="data.item.listChecked"></b-form-checkbox>
        </template>
        <template v-slot:cell(detail)="data">
            <b-form-checkbox v-model="data.item.detailChecked"></b-form-checkbox>
        </template>
        <template v-slot:cell(search)="data">
            <b-form-checkbox v-model="data.item.searchChecked"></b-form-checkbox>
        </template>
        <template v-slot:cell(search_condition)="data">
            <b-form-checkbox v-model="data.item.searchConditionChecked"></b-form-checkbox>
        </template>
        <template v-slot:cell(search_show_title)="data">
            <b-form-checkbox v-model="data.item.searchShowTitleChecked"></b-form-checkbox>
        </template>
        <template v-slot:cell(order)="data">
            <b-form-select v-model="data.value" :options="orderOptions" @change="changeItem($event, data.item, data.field.key)" />
        </template>
    </b-table>
    <div class="d-flex justify-content-between px-2">
        <ButtonAdd @click="attributeAddEmptyInput"></ButtonAdd>
        <div>
            <ButtonSave @click="updateOrCreateAttributes(false)"></ButtonSave>
            <ButtonDelete @click="deleteAttributes"></ButtonDelete>
        </div>
    </div>
</div>
</template>

<script>
import ButtonEdit from "../components/buttons/ButtonEdit.vue";
import ButtonAdd from "../components/buttons/ButtonAdd.vue";
import ButtonDelete from "../components/buttons/ButtonDelete.vue";
import ButtonSave from "../components/buttons/ButtonSave.vue";
import * as queries from "../graphql/locus_queries"
import * as mutations from "../graphql/locus_mutations";
import global from "../Global.vue";
import AwsApi from '../Api';
import {
    API
} from "aws-amplify";

export default {
    components: {
        ButtonSave,
        ButtonDelete,
        ButtonAdd,
        ButtonEdit
    },
    computed: {
        attributes() {
            return [...this.attributesApi, ...this.attributesAdd]
        }
    },
    data() {
        return {
            tenantId: "",
            attributesApi: [],
            attributesAdd: [],
            sourceTable: "ItemMaster",
            sourceTable2: "EPC",
            header: [{
                key: "delete",
                label: this.$t("select"),
            }, {
                key: "name",
                label: this.$t("attr_name"),
            }, {
                key: "list",
                label: this.$t("attr_list"),
            }, {
                key: "detail",
                label: this.$t("attr_detail"),
            }, {
                key: "search_condition",
                label: this.$t("attr_search_condition"),
            }, {
                key: "search",
                label: this.$t("attr_search"),
            }, {
                key: "search_show_title",
                label: this.$t("search_show_title"),
            }, {
                key: "order",
                label: this.$t("display_order"),
            }],
            nameList: []
        }
    },
    mounted() {
        this.tenantId = localStorage.getItem(global.tenantId)
        this.fetchAttributes()
    },
    methods: {
        async fetchAttributes() {
            this.attributesApi = []
            this.attributesAdd = []
            const response = await AwsApi.graphql({
                query: queries.queryItemDefines,
                variables: {
                    sourceTable: {
                        eq: this.sourceTable
                    },
                    tenantId: this.tenantId
                }
            }, this);
            const response2 = await AwsApi.graphql({
                query: queries.queryItemDefines,
                variables: {
                    sourceTable: {
                        eq: this.sourceTable2
                    },
                    tenantId: this.tenantId
                }
            }, this);
            this.attributesApi = [...this.attributeMapApiResponse([...response.data.queryItemDefines.items, ...response2.data.queryItemDefines.items])]
        },

        attributeMapApiResponse(response) {
            const _this = this
            const resSort = response.sort(function (a, b) {
                return a.order - b.order
            });

            _this.nameList = []
            resSort.forEach(element => {
                _this.nameList.push({
                    value: element.source,
                    text: element.name
                })
                return element
            });

            const resfilter = resSort.filter(item => {
                return item.showOnApp !== undefined && item.showOnApp !== null && item.showOnApp.length > 0 ||
                    item.searchOnApp !== undefined && item.searchOnApp !== null && item.searchOnApp.length > 0
            })
            const showList = []
            resfilter.forEach((filterItem, index) => {
                const element = filterItem
                element["listChecked"] = false
                element["detailChecked"] = false
                element["searchChecked"] = false
                element["searchConditionChecked"] = false
                element["searchShowTitleChecked"] = false
                element["isDisabled"] = true
                element["order"] = index + 1
                if (element.showOnApp !== undefined && element.showOnApp !== null && element.showOnApp.length > 0) {
                    element.showOnApp.forEach(item => {
                        if (item === 2) {
                            element["listChecked"] = true
                        } else if (item === 3) {
                            element["detailChecked"] = true
                        } else if (item === 4) {
                            element["searchConditionChecked"] = true
                        } else if (item === 5) {
                            element["searchShowTitleChecked"] = true
                        }
                    })
                }
                if (element.searchOnApp !== undefined && element.searchOnApp !== null && element.searchOnApp.length > 0) {
                    element.searchOnApp.forEach((item, index) => {
                        const tmp = {
                            ...element
                        }
                        if (index != 0) {
                            tmp["listChecked"] = false
                            tmp["detailChecked"] = false
                            tmp["searchConditionChecked"] = false
                            tmp["searchShowTitleChecked"] = false
                            tmp["searchChecked"] = true
                            showList.push(tmp)
                        } else {
                            tmp["searchChecked"] = true
                            showList.push(tmp)
                        }

                    })
                } else {
                    showList.push(element)
                }
            })
            this.setOrderOptions(showList)
            return showList.map((item) => ({
                ...item,
                checked: false
            }))
        },
        setOrderOptions(showList) {
            this.orderOptions = showList.map((item, index) => ({
                value: index + 1,
                text: (index + 1).toString()
            }));
        },

        attributeAddEmptyInput() {
            const emptyAttribute = {
                name: null,
                checked: false,
                listChecked: false,
                detailChecked: false,
                searchChecked: false,
                searchConditionChecked: false,
                searchShowTitleChecked: false,
                order: this.attributes.length + 1
            };
            this.attributesAdd = [...this.attributesAdd, emptyAttribute]
            this.setOrderOptions(this.attributes)
        },

        async deleteAttributes() {

            this.attributesApi.filter(attribute => attribute.checked)
                .forEach(element => {
                    element.listChecked = false
                    element.detailChecked = false
                    element.searchChecked = false
                    element.searchConditionChecked = false
                    element.searchShowTitleChecked = false
                });
            this.attributesAdd = this.attributesAdd.filter(attribute => !attribute.checked)

            this.updateOrCreateAttributes(true)
        },

        async updateOrCreateAttributes(deleteFlag) {
            AwsApi.checkSession(() => {

                const update = [];
                var create_and_update = [];
                if (deleteFlag) {
                    create_and_update = [...this.attributesApi];
                } else {
                    create_and_update = [...this.attributesApi, ...this.attributesAdd];
                }
                const updateAttributes = []
                var sort = 1
                create_and_update.forEach(element => {
                    var listChecked = element.listChecked
                    var detailChecked = element.detailChecked
                    var searchChecked = element.searchChecked
                    var searchConditionChecked = element.searchConditionChecked
                    var searchShowTitleChecked = element.searchShowTitleChecked

                    const showOnApp = []
                    if (listChecked) {
                        showOnApp.push(2)
                    }
                    if (detailChecked) {
                        showOnApp.push(3)
                    }
                    if (searchConditionChecked) {
                        showOnApp.push(4)
                    }
                    if (searchShowTitleChecked) {
                        showOnApp.push(5)
                    }
                    const index = updateAttributes.findIndex(item => {
                        return item.source === element.source
                    })
                    if (index === -1) {
                        updateAttributes.push({
                            group: this.tenantId,
                            tenantId: this.tenantId,
                            source: element.source,
                            showOnApp: showOnApp,
                            searchOnApp: searchChecked ? [sort++] : [],
                            order: element.order
                        })
                    } else {
                        if (searchChecked) {
                            updateAttributes[index].searchOnApp = [...updateAttributes[index].searchOnApp, sort++]
                        }
                        updateAttributes[index].showOnApp = [...updateAttributes[index].showOnApp, ...showOnApp]
                    }
                })

                updateAttributes.forEach(element => {
                    update.push(API.graphql({
                        query: mutations.updateItemDefine,
                        variables: {
                            input: element
                        }
                    }));

                })
                Promise.all([...update]).then(() => this.fetchAttributes())
            }, this)
        },
        changeItem(value, cell, key) {
            cell[key] = value;
        },
    }
}
</script>

<style>
.hidden_header {
    display: none;
}
</style>
