<template>
<section>
    <div class="row">
        <div class="col-12">
            <div class="card-box">
                <slot name="back"></slot>
                <br>

                <b-table class="tablesaw" :busy="loading" :items="items" :fields="fields" striped bordered @headClicked="sortChange" responsive :select-head="false" @rowSelected="$emit('row-selected', $event)" @rowUnselected="$emit('un-row-selected', $event)" :selectable="title === 'Stocks'" select-mode="single">
                    <template v-slot:head()="data">
                        <div style="white-space: nowrap">
                            <span>{{ data.label}}</span>
                            <i-bi-caret-down-fill v-if="fields.find(item=> item.label === data.label) === undefined || fields.find(item=> item.label === data.label).sortDirection === undefined" style="visibility: hidden" />
                            <i-bi-caret-up-fill v-else-if="fields.find(item=> item.label === data.label).sortDirection === 'asc'" />
                            <i-bi-caret-down-fill v-else-if="fields.find(item=> item.label === data.label).sortDirection === 'desc'" />
                        </div>
                    </template>

                </b-table>

                <b-pagination align="center" :total-rows="paginate.total" :per-page="Number(paginate.per_page)" v-model="paginate.current_page" @page-click="updatePage" class="my-0" />

                <slot name="button"></slot>

            </div>
        </div>
    </div>

</section>
</template>

<script>
import Paginate from '../ThePaginate.vue'
import {
    BPagination,
    BTable
} from "bootstrap-vue-next";

export default {
    props: ['headers', 'loading', 'items', 'paginate', 'sort', 'title'],
    components: {
        Paginate,
        BTable,
        BPagination,
    },
    computed: {
        fields() {
            let headers = this.headers
                .map((header) => {
                    if (header.source.includes(" as ")) {
                        header.source = header.source.split(" as ")[1]
                    }
                    
                    let index = 0;
                    if (this.sort.map(sort => sort.source).includes(header.source)) {

                        const direction = this.sort.find((item, i) => {
                            index = i;
                            return item.source === header.source;
                        }).direction === 'asc' ? 'asc' : 'desc';
                        return {
                            id: header.id,
                            key: header.source,
                            name: header.name,
                            index: index,
                            direction: direction,
                            class: ['sorting', "word-break-keep", direction],
                        }
                    } else {

                        return {
                            id: header.id,
                            key: header.source,
                            name: header.name,
                            class: ['sorting', "word-break-keep"],
                        }
                    }
                })
                .map((item) => {
                    const index = item.index >= 0 ? item.index + 1 : '';
                    return {
                        key: item.key,
                        label: `${item.name}`,
                        thClass: item.class,
                        sortDirection: item.direction === undefined ? '' :item.direction,
                        thAttr: {
                            index: index
                        }
                    }
                });
            return headers;
        }
    },
    methods: {
        rowSelected(item) {
            this.$emit('rowSelected', item)
        },
        sortChange(sort) {
            this.$emit('sortChange', sort)
        },
        updatePage(BvEvent, page) {
            this.page = page;
            this.$emit('updatePage', page);
        },
    }
}
</script>

<style>
.table-yellow {
    background-color: yellow;
}

.table-grey {
    background-color: lightgrey;
}

.table-white {
    background-color: white;
}
</style>
