<template>
    <path :fill="fill" :style="_style" transform="rotate(45 12.00000000000001,14.499771118164059) " id="svg_1" d="m5.5,9.49977a1.5,1.5 0 0 1 -1.5,-1.5a1.5,1.5 0 0 1 1.5,-1.5a1.5,1.5 0 0 1 1.5,1.5a1.5,1.5 0 0 1 -1.5,1.5m15.91,4.58l-9,-9c-0.36,-0.36 -0.86,-0.58 -1.41,-0.58l-7,0c-1.11,0 -2,0.89 -2,2l0,7c0,0.55 0.22,1.05 0.59,1.41l8.99,9c0.37,0.36 0.87,0.59 1.42,0.59c0.55,0 1.05,-0.23 1.41,-0.59l7,-7c0.37,-0.36 0.59,-0.86 0.59,-1.41c0,-0.56 -0.23,-1.06 -0.59,-1.42z"/>
</template>

<script>
    export default {
        name: "LocationTag",
        props: {
            fill: {
                type: String,
                default: 'black'
            },
            _style: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style scoped>

</style>
