<template>
<app-page title="Location">
    </app-page>
</template>

<script>
import LocationPage from './LocationPage.vue'
export default {
    components: {
        'app-page': LocationPage,
    }
}
</script>
