<template>
<!-- Top Bar Start -->
<div class="topbar" style="height:70px">
    <!-- LOGO -->
    <div class="topbar-left">
        <div class="text-center">
            <a class="logo">
                <i class="ti-cloud"></i>
                <span>Locus Mapping</span>
            </a>
        </div>
    </div>

    <!-- Button mobile view to collapse sidebar menu -->

    <nav class="navbar-custom">
        <ul class="list-inline float-right">
            <b-dropdown variant="default" toggle-class="nav-link dropdown-toggle shadow-none" no-caret>
                <template #button-content>
                    <span class="mdi mdi-account-circle mdi-24px" v-wave style="font-size: x-large;padding: 0.85em 1.1em;"></span>
                </template>
                <b-dropdown-item href="#" @click="logout">Logout</b-dropdown-item>
            </b-dropdown>
        </ul>
    </nav>
</div>
<!-- Top Bar End -->
</template>

<script>
import {
    Auth
} from "aws-amplify";

export default {
    methods: {
        logout() {
            Auth.signOut()
            this.$router.push("/");
        },
    },
};
</script>

<style lang="scss">
.navbar-custom .float-right {
    .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
        border-color: transparent !important;
    }
}

</style>
