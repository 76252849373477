<!-- RecursiveTree.vue -->
<template>
        <template  v-for="item in items" :key="item.id">
            <BTr>
                <BTd style="width:100px">
                    <span class="number" v-if='item["ItemMaster.p2"] === undefined && item["ItemMaster.p3"] === undefined && item["ItemMaster.p4"] === undefined'>
                        <span @click="toggleChildren(item, level)">
                            <img v-if="item.showChildren" src="/icons-minus.png" width="24" height="24" />
                            <img v-else src="/icons-plus.png" width="24" height="24" /></span>&nbsp;&nbsp;
                        <span @click="showDetailLocation(item, 1)">{{item["ItemMaster.p1"]}}</span>
                    </span></BTd>
                <BTd style="width:100px">
                    <span class="number" v-if='item["ItemMaster.p2"] !== undefined && item["ItemMaster.p3"] === undefined && item["ItemMaster.p4"] === undefined'>
                        <span @click="toggleChildren(item, level)"><img v-if="item.showChildren" src="/icons-minus.png" width="24" height="24" />
                        <img v-else src="/icons-plus.png" width="24" height="24" /></span>&nbsp;&nbsp;
                        <span @click="showDetailLocation(item, 2)">{{item["ItemMaster.p2"]}}</span>
                    </span></BTd>
                <BTd style="width:100px">
                    <span class="number" v-if='item["ItemMaster.p3"] !== undefined && item["ItemMaster.p4"] === undefined'>
                        <span @click="toggleChildren(item, level)"><img v-if="item.showChildren" src="/icons-minus.png" width="24" height="24" />
                        <img v-else src="/icons-plus.png" width="24" height="24" /></span>&nbsp;&nbsp;
                        <span @click="showDetailLocation(item, 3)">{{item["ItemMaster.p3"]}}</span>
                    </span></BTd>
                <BTd style="width:100px" ><span class="number" v-if='item["ItemMaster.p4"] !== undefined' @click="showDetailLocation(item, 4)">&nbsp;&nbsp;{{item["ItemMaster.p4"]}}</span></BTd>
                <BTd>
                  <template v-if="item['realAmount'] !== undefined">
                            <span :class="item['expectAmount'] < item['realAmount'] ? 'notenough1' : (item['expectAmount'] > item['realAmount'] ? 'overstock1':'number1')" >
                                {{item['expectAmount']}}
                            </span>
                        </template>
                        <template v-else>
                            <span class="number1">
                                {{item['expectAmount']}}
                            </span>
                        </template>
                  {{}}
                  </BTd>
                <BTd>
                  <template v-if="item['expectAmount'] !== undefined">
                            <span :class="item['realAmount'] < item['expectAmount'] ? 'notenough1' : (item['realAmount'] > item['expectAmount'] ? 'overstock1':'number1')">
                                {{item['realAmount']}}
                            </span>
                        </template>
                        <template v-else>
                            <span class="number1">
                                {{item['realAmount']}}
                            </span>
                        </template>
                  </BTd>
            </BTr>
             <RecursiveTree
                v-if="item.children && item.showChildren"
                :items="item.children"
                :level="level + 1"
                @fetch-children="fetchChildren"
                @show-detail-location="showDetailLocation"
            />
        </template>
</template>

<script>
import {
  BTableSimple,
  BTfoot,
  BTr,
  BTh,
  BTbody,
  BThead
} from 'bootstrap-vue-next'

export default {
  name: "RecursiveTree",
  props: {
    items: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
  },
  components: {
        BTableSimple,
        BTfoot,
        BTr,
        BTh,
        BTbody,
        BThead
    },
  emits: ['fetch-children', 'show-detail-location'],
  methods: {
    fetchChildren(item, level){
        this.$emit("fetch-children", item, level);
    },
    showDetailLocation(item, level){
        this.$emit("show-detail-location", item, level);
    },
    toggleChildren(item, level) {
      if (!item.showChildren) {
        item.showChildren = true;
        if (!item.children) {
          this.$emit("fetch-children", item, this.level);
        }
      } else {
        item.showChildren = false;
      }
    },
  },
};
</script>
<style scoped>
.number {
    color: #007bff;
    cursor: pointer;
}
</style>