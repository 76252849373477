<template>
  <section v-if="!showLocationDetailFlag">
    <div class="row">
      <div class="col-12">
        <div class="card-box">
          <span
            style="
              font-weight: 600;
              display: inline-block;
              padding: 1rem;
              padding-left: 0.5rem;
              cursor: pointer;
            "
            @click="$emit('backToMainPage')"
          >
            &lt;&lt; back</span
          >
          <br />
          <!-- Loading Overlay -->
      
          <BTableSimple
            responsive
            striped
            bordered
            v-if="!showLocationDetailFlag"
          >
            <BThead style="background: #f5f5f5">
              <BTr>
                <BTh colspan="4">ロケ番号</BTh>
                <BTh>予定数</BTh>
                <BTh>実績数</BTh>
              </BTr>
            </BThead>
            <BTbody>
              <RecursiveTree
                :items="allData"
                @fetch-children="fetchChildren"
                @show-detail-location="showDetailLocation"
              />
            </BTbody>
          </BTableSimple>
          <!-- <slot name="buttonfinish"></slot> -->
          <slot name="button"></slot>
            <div v-if="loading" class="loading-overlay d-flex justify-content-center align-items-center">
            <b-spinner></b-spinner>
            <span class="ml-2">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <LocationTableDetail
    v-else
    :headers="headers"
    :items="currentLocationData"
    :sort="sortBy"
    :title="title"
    @sortChange="sortChange"
    name="table-simple-modal-item"
    @backToLocationPage="backToLocationPage"
    @updatePage="updatePage"
    :paginate="paginate"
    :loading="loading"
  ></LocationTableDetail>
</template>

<script>
import LocationTableDetail from "./LocationTableDetail.vue";
import RecursiveTree from "./RecursiveTree.vue";
import * as queries from "../../../graphql/locus_queries";
import AwsApi from "../../../Api";
import { BTableSimple, BTbody } from "bootstrap-vue-next";
export default {
  props: ["infos", "tenantId", "url", "title", "form", "formItems"],
  data() {
    return {
      headers: [],
      items: [],
      loading: false,
      sortBy: [],
      direction: null,
      limit: 1000,
      offset: 0,
      allData: [],
      currentLocationData: [],
      list: [],
      showLocationDetailFlag: false,

      allDetailData: [],
      count: 30,
      page: 1,
      paginate: {},
      currentLocationItem: null,
      currentLevel:null
    };
  },
  components: {
    LocationTableDetail,
    RecursiveTree,
    BTableSimple,
    BTbody,
  },
  mounted() {
    this.clearData();
    this.fetchMenuConfigs();

    this.getListData();
  },
  methods: {
    async fetchChildren(item, level) {
      const cachedData = this.list.find(
        (i) =>
          (level === 1 &&
            i.p1 === item["ItemMaster.p1"] &&
            i.p2 === undefined &&
            i.p3 === undefined &&
            i.p4 === undefined) ||
          (level === 2 &&
            i.p1 === item["ItemMaster.p1"] &&
            i.p2 === item["ItemMaster.p2"] &&
            i.p3 === undefined &&
            i.p4 === undefined) ||
          (level === 3 &&
            i.p1 === item["ItemMaster.p1"] &&
            i.p2 === item["ItemMaster.p2"] &&
            i.p3 === item["ItemMaster.p3"] &&
            i.p4 === undefined)
      );
      if (cachedData) {
        item.children = cachedData.children;
        return;
      }
      this.offset = 0;
      const datas = await this.getListData(item, level);
      item.children = datas;
      switch (level) {
        case 1: {
          this.list.push({ p1: item["ItemMaster.p1"], children: datas });
          break;
        }
        case 2: {
          this.list.push({
            p1: item["ItemMaster.p1"],
            p2: item["ItemMaster.p2"],
            children: datas,
          });
          break;
        }
        case 3: {
          this.list.push({
            p1: item["ItemMaster.p1"],
            p2: item["ItemMaster.p2"],
            p3: item["ItemMaster.p3"],
            children: datas,
          });
          break;
        }
        default: {
        }
      }
    },
    async fetchMenuConfigs() {
      const response = await AwsApi.graphql(
        {
          query: queries.getMenu,
          variables: {
            tenantId: this.tenantId,
            id: this.url.menu_id,
          },
        },
        this
      );
      if (response === undefined) {
        return;
      }
      this.displayTitle = response.data.getMenu.name;
      this.menuConfigs = response.data.getMenu.configs.items;
      if (this.menuConfigs.length === 0) {
        return;
      }
      this.setTableFields(this.menuConfigs);
    },
    setTableFields(menuConfigs) {
      this.headers = menuConfigs
        .filter((item) => item.detailOrder && item.detailOrder !== -1)
        .sort((a, b) => a.detailOrder - b.detailOrder);

      this.headers.unshift({ source: "location", name: `ロケ番号` });

      // this.sortBy = [{
      //     direction: 'asc',
      //     source: "JobEpc.updatedAt"
      // }];
    },
    async getListData(item, level, searchType = 1, accumulatedData = []) {
      this.loading = true
      const myInit = this.setApiParam(searchType);
      switch (level) {
        case 1: {
          myInit["ItemMaster.p1"] = item["ItemMaster.p1"];
          break;
        }
        case 2: {
          myInit["ItemMaster.p1"] = item["ItemMaster.p1"];
          myInit["ItemMaster.p2"] = item["ItemMaster.p2"];
          break;
        }
        case 3: {
          myInit["ItemMaster.p1"] = item["ItemMaster.p1"];
          myInit["ItemMaster.p2"] = item["ItemMaster.p2"];
          myInit["ItemMaster.p3"] = item["ItemMaster.p3"];
          break;
        }
        case 4: {
          myInit["ItemMaster.p1"] = item["ItemMaster.p1"];
          myInit["ItemMaster.p2"] = item["ItemMaster.p2"];
          myInit["ItemMaster.p3"] = item["ItemMaster.p3"];
          myInit["ItemMaster.p4"] = item["ItemMaster.p4"];
          break;
        }
        default: {
        }
      }
      const response = await AwsApi.graphql(
        {
          query: queries.searchCustomerJobs,
          variables: {
            conditions: JSON.stringify(myInit),
          },
        },
        this
      );
      if (response === undefined) {
        this.loading = false
        return;
      }
      const convertData = response.data.searchCustomerJobs.map((item) => {
        return JSON.parse(item);
      });
      if (searchType === 2) {
        this.allDetailData = this.allDetailData.concat(convertData);
        this.setData();
        this.loading = false
      } else {
        if (level === undefined) {
          this.allData = this.allData.concat(convertData);
        } else {
          accumulatedData.push(...convertData);
        }
        if (response.data.searchCustomerJobs.length === 1000) {
          this.offset += this.limit;
          return await this.getListData(
            item,
            level,
            searchType,
            accumulatedData
          );
        } else {
          this.loading = false
          return accumulatedData;
        }
      }
    },
    updatePage(page) {
      this.page = page;
      if (this.page * this.count >= this.limit + this.offset) {
        this.offset += this.limit;
        this.getListData(this.currentLocationItem, this.currentLevel, 2);
      } else {
        this.setData();
      }
    },
    setData() {
      this.currentLocationData = this.allDetailData.slice(
        (this.page - 1) * this.count,
        this.page * this.count
      );
      this.paginate = {
        total: this.allDetailData.length,
        per_page: this.count,
        current_page: this.page,
      };
    },
    setApiParam(searchType = 1) {
      var myInit = {};
      for (const key in this.form) {
        const hasKey = this.formItems.some((i) => i.options === key);
        const isDate = this.formItems.some(
          (i) =>
            i.sourceDefine &&
            (i.sourceDefine.inputType === 2 ||
              i.sourceDefine.inputType === 4 ||
              i.sourceDefine.inputType === 6) &&
            i.options === key
        );
        if (hasKey) {
          if (this.form[key] !== null && this.form[key] !== "") {
            //myInit.body[key] = this.form[key]
            if (typeof this.form[key] === "boolean") {
              if (this.form[key] === true) {
                myInit[key] = this.form[key];
              }
            } else if (typeof this.form[key] == "string" && !isDate) {
              myInit[key] = "%" + this.form[key] + "%";
            } else {
              myInit[key] = this.form[key];
            }
          }
        }
      }
      myInit = {
        ...myInit,
        menuId: this.url.menu_id,
        limit: this.limit,
        offset: this.offset,
        warehouseId: this.url.warehouse_id,
        searchType: searchType,
        ...this.infos,
      };
      return myInit;
    },
    async showDetailLocation(item, level) {
      this.clearDetailData()
      this.showLocationDetailFlag = true;
      this.currentLocationItem = item
      this.currentLevel = level
      this.getListData(item, level, 2);
      
    },
    clearDetailData(){
      this.offset = 0;
      this.paginate = {};
      this.page = 1;
      this.allDetailData = [];
      this.currentLocationData = [];
    },
    clearData() {
      this.allData = [];
      this.clearDetailData()
    },
    backToLocationPage() {
      this.showLocationDetailFlag = false;
      this.currentLocationData = [];
    },
    sortChange(value) {
      //check if value already in array
      const exist = this.sortBy.map((item) => item.source).includes(value);
      // store the new value if not exist or change direction
      this.sortBy = exist
        ? this.sortBy.map((item) => {
            if (item.source === value) {
              item.direction = item.direction === "asc" ? "desc" : "asc";
            }
            return item;
          })
        : [
            {
              source: value,
              direction: "asc",
            },
          ];
      this.sortData();
    },
    sortData() {
      if (this.sortBy.length === 0) {
        return;
      }
      this.currentLocationData.sort((a, b) => {
        const sort = this.sortBy[0];

        const valA = a[sort.source];
        const valB = b[sort.source];

        if (valA == null && valB == null) return 0;
        if (valA == null) return sort.direction === "asc" ? 1 : -1;
        if (valB == null) return sort.direction === "asc" ? -1 : 1;

        if (typeof valA === "number") {
          if (sort.direction === "asc") {
            return valA < valB ? -1 : 1;
          } else {
            return valA > valB ? -1 : 1;
          }
        } else {
          if (sort.direction === "asc") {
            return valA.localeCompare(valB);
          } else {
            return valB.localeCompare(valA);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.modal-item-header {
  display: flex;
  justify-content: space-around;
}

.modal-item-header-key {
  font-weight: 600;
}

.modal-item-header-value {
  display: inline-block;
  margin-left: 4px;
  margin-right: 1rem;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>