<template>
<div>
    <b-table striped responsive borderless :fields="header" :items="attributes">
        <template v-slot:cell(delete)="data">
            <template v-if="data.item.key === null || (data.item.key.includes('itemCode') !== true && data.item.key.includes('name') !== true && data.item.key.includes('systemId') !== true)">
                <b-form-checkbox v-model="data.item.checked"></b-form-checkbox>
            </template>
        </template>
        <template v-slot:cell(key)="data">
            <template v-if="data.item.key !== null || data.item.isDisabled">
                <b-form-input v-model="data.item.key" disabled></b-form-input>
            </template>
            <template v-else>
                <b-form-select v-model="data.item.key" :options="keyOption" @change="changeItemKey($event,data.item, data.field.key)">
                </b-form-select>
            </template>
        </template>
        <template v-slot:cell(name)="data">
            <b-form-input v-model="data.item.name" @input="changeItem($event, data.item, data.field.key)" :disabled="data.item.isDisabled"></b-form-input>
        </template>
        <template v-slot:cell(inputType)="data">
            <template v-if="data.item.key !== 'itemCode' && data.item.key !== 'name' && data.item.key !== 'systemId'">
                <b-form-select v-model="data.item.inputType" :options="[
                            { value: 0, text: 'Text'},
                            { value: 1, text: 'List'},
                            { value: 2, text: 'Date' },
                            { value: 4, text: 'DateTime' },
                            { value: 5, text: 'Checkbox' },
                            { value: 6, text: 'Checkbox And Date' }
                        ]" @change="changeSelectItem($event, data.item, data.field.key)" :disabled="data.item.isDisabled">
                </b-form-select>
                <b-form-input v-if="data.item.showInputFlag" v-model="data.item.items" @input="changeInputItem($event, data.item, data.field.key)" style="margin-top:10px"></b-form-input>
                <div class="attr_warning" v-if="data.item.checkInputFlag">入力データはJSONではありません。</div>
            </template>
        </template>
    </b-table>
    <div class="d-flex justify-content-between px-2">
        <ButtonAdd @click="attributeAddEmptyInput"></ButtonAdd>
        <div>
            <ButtonSave @click="updateOrCreateAttributes"></ButtonSave>
            <ButtonDelete @click="deleteAttributes"></ButtonDelete>
        </div>
    </div>
    <div class="attr_warning">properties以外の変更がある場合は、マスタデータの再登録する必要があります。</div>
</div>
</template>

<script>
import ButtonEdit from "../components/buttons/ButtonEdit.vue";
import ButtonAdd from "../components/buttons/ButtonAdd.vue";
import ButtonDelete from "../components/buttons/ButtonDelete.vue";
import ButtonSave from "../components/buttons/ButtonSave.vue";
import * as queries from "../graphql/locus_queries"
import * as mutations from "../graphql/locus_mutations";
import global from "../Global.vue";
import AwsApi from '../Api';
import {
    API
} from "aws-amplify";

export default {
    props: ['type'],
    components: {
        ButtonSave,
        ButtonDelete,
        ButtonAdd,
        ButtonEdit,
    },
    computed: {
        attributes() {
            return [...this.attributesApi, ...this.attributesAdd]
        }
    },
    data() {
        return {
            tenantId: "",
            attributesApi: [],
            attributesAdd: [],
            attr_errors: [],
            sourceTable: "ItemMaster",
            header: [{
                key: "delete",
                label: this.$t("select"),
            }, {
                key: "key",
                label: this.$t("attr_key"),
            }, {
                key: "name",
                label: this.$t("attr_name"),
            }, {
                key: "inputType",
                label: this.$t("attr_type"),
            }],
            keyOptionDefault: [],
            keyOption: []
        }
    },
    mounted() {
        this.tenantId = localStorage.getItem(global.tenantId)
        if (this.type === 0) {
            this.sourceTable = "ItemMaster"
        } else {
            this.sourceTable = "Job"
        }
        this.fetchAttributes()
        this.getDefaultKey()

    },
    methods: {
        async fetchAttributes() {
            const response = await AwsApi.graphql({
                query: queries.queryItemDefines,
                variables: {
                    sourceTable: {
                        eq: this.sourceTable
                    },
                    tenantId: this.tenantId
                }
            }, this);
            this.attributesApi = [...this.attributeMapApiResponse(response.data.queryItemDefines.items)]
            this.setKeyOption()
        },
        async getDefaultKey() {
            const response = await AwsApi.graphql({
                query: queries.getAddableItems,
                variables: {
                    tableName: this.sourceTable
                }
            }, this);
            this.keyOptionDefault = response.data.getAddableItems
            this.setKeyOption()
        },
        async setKeyOption() {
            this.keyOption = this.keyOptionDefault.filter(item1 => item1 === "properties" || !this.attributes.some(item2 => {
                return item2.source !== undefined && item2.source.includes(item1) || item2.key !== null && item2.key.includes(item1)
            }));
        },
        attributeMapApiResponse(response) {
            const resSort = response.sort(function (a, b) {
                return a.order - b.order
            });
            const properties = []
            var allAttributes = []

            resSort.forEach(item => {
                const current = allAttributes.find(attr => item.source.includes(attr.key))
                if (current !== undefined) {
                    current.name = item.name
                    current.inputType = item.inputType
                    current["source"] = item.source
                    current.toCreate = false
                    current.order = item.order
                } else {
                    if (item.source.includes("properties")) {
                        item["isDisabled"] = true
                    }
                    
                    properties.push(item)
                }
            })
            allAttributes = [...allAttributes, ...properties]
            const res = allAttributes.map((element) => {
                if(element.inputType === 1){
                    element.showInputFlag = true
                } else {
                    element.showInputFlag = false
                }
                if (element.source) {
                    const items = element.source.split('.')
                    element["key"] = items[1]
                    element["checked"] = false
                    return element
                } else {
                    element["checked"] = false
                    return element
                }
            })
            return res
        },

        attributeAddEmptyInput() {
            const emptyAttribute = {
                key: null,
                name: null,
                inputType: 0,
                checked: false,
                toCreate: true
            };
            return this.attributesAdd = [...this.attributesAdd, emptyAttribute]
        },

        async deleteAttributes() {
            AwsApi.checkSession(() => {
                const deletePro = []

                const item_to_delete = this.attributesApi
                    .filter(attribute => attribute.checked)
                    .map(attribute => attribute.source);
                this.attributesAdd = this.attributesAdd.filter(attribute => !attribute.checked)
                if (item_to_delete.length > 0) {
                    API.graphql({
                        query: queries.listMenuConfigs,
                        variables: {
                            tenantId: this.tenantId
                        }
                    }).then(response => {
                        item_to_delete.forEach((item, index) => {
                            var deleteAttribute = {
                                tenantId: this.tenantId,
                                source: item
                            }
                            deletePro.push(API.graphql({
                                query: mutations.deleteItemDefine,
                                variables: {
                                    input: deleteAttribute
                                }
                            }));

                            response.data.listMenuConfigs.items.forEach(menuItem => {
                                if (item === menuItem.source) {
                                    const deleteItem = {
                                        tenantId: this.tenantId,
                                        id: menuItem.id
                                    }
                                    deletePro.push(API.graphql({
                                        query: mutations.deleteMenuConfig,
                                        variables: {
                                            input: deleteItem
                                        }
                                    }));
                                }
                            })
                        })
                        Promise.all([...deletePro]).then(() => this.fetchAttributes())
                    })
                }
                this.setKeyOption()
            }, this)
        },

        async updateOrCreateAttributes() {
            AwsApi.checkSession(async () => {
                const create = []
                const update = []
                const deletePro = []

                var item_to_create = [...this.attributesAdd.filter(attr => attr.key !== null && attr.name !== "" && attr.name !== null), ...this.attributesApi.filter(attr => attr.toCreate && attr.name !== "" && attr.name !== null)];

                const uniqueArray = [];

                item_to_create.forEach(item => {
                    if (!uniqueArray.some(obj => obj.key === item.key && obj.key !== "properties")) {
                        uniqueArray.push(item);
                    }
                });
                item_to_create = uniqueArray
                const item_to_update = [...this.attributesApi.filter(attr => !attr.toCreate && attr.toUpdate)];

                var order = 0
                if (this.attributesApi.length != 0) {
                    order = this.attributesApi[this.attributesApi.length - 1].order + 1
                }

                item_to_create.forEach((item, index) => {
                    var createAttribute = {
                        group: this.tenantId,
                        tenantId: this.tenantId,
                        source: item.key.includes('properties') === false ? this.sourceTable + "." + item.key : this.sourceTable + "." + item.key + "." + item.name,
                        name: item.name,
                        sourceTable: this.sourceTable,
                        inputType: item.inputType,
                        items: item.items,
                        order: order + index
                    }
                    create.push(API.graphql({
                        query: mutations.createItemDefine,
                        variables: {
                            input: createAttribute
                        }
                    }));
                    this.attributesAdd = this.attributesAdd.filter(attr => item.id !== attr.id)
                })
                item_to_update.forEach((item, index) => {
                    item.toUpdate = false
                    var updateAttribute = {
                        group: this.tenantId,
                        tenantId: this.tenantId,
                        source: item.key.includes('properties') === false ? this.sourceTable + "." + item.key : this.sourceTable + "." + item.key + "." + item.name,
                        name: item.name,
                        sourceTable: this.sourceTable,
                        inputType: item.inputType,
                        items: item.items
                    }
                    update.push(API.graphql({
                        query: mutations.updateItemDefine,
                        variables: {
                            input: updateAttribute
                        }
                    }));
                })
                Promise.all([...create, ...update, ...deletePro]).then(() => this.fetchAttributes())
            }, this)
        },
        changeItem(value, cell, key) {
            cell.toUpdate = true
        },
        changeSelectItem(value, cell, key) {
            cell.toUpdate = true
            if(value === 1){
                cell.showInputFlag = true
            } else {
                cell.showInputFlag = false
                cell.items = null
            }
        },
        changeInputItem(value, cell, key) {
            try {
                JSON.parse(value);
                cell.toUpdate = true
                if(value === "") {
                    cell.items = null
                } else {
                    cell.items = value
                }
                cell.checkInputFlag = false
            } catch (e) {
                cell.checkInputFlag = true
            }
        },
        changeItemKey(value, cell, key) {
            cell.key = value
            this.setKeyOption()
        }
    },

}
</script>

<style>
.hidden_header {
    display: none;
}

.attr_warning {
    float: left;
    margin-top: 15px;
    font-weight: 600;
    color: red;
}
</style>
