<template>
<form class="form-horizontal" role="form" v-on:submit.prevent="submit">
    <i style="position: absolute; right: 20px; top: 12px; font-size: 2rem" v-show="!showOn" class="fa fa-angle-up" @click="showOn = !showOn"></i>
    <i style="position: absolute; right: 20px; top: 12px; font-size: 2rem" v-show="showOn" class="fa fa-angle-down" @click="showOn = !showOn"></i>

    <div v-show="showOn" class="mt-4">
        <div class="form-row form_container">
            <div v-for="item in items" :key="item.id" :form-data="form_select_options" class="form-group col-auto">

                <div v-if="item.type === 'checkbox_and_date'">
                    {{item.title}}
                    <div class="input-group input-daterange ">
                        <div style="margin-left:-6px;margin-top:6px;margin-right: 28px;">
                            <b-form-checkbox v-model="check[item.options]" @change="updateItem($event)"></b-form-checkbox>
                        </div>
                        <VueDatePicker style="width:210px" class="datepicker_border" v-model="date[item.options + '_start']" placeholder="Select date" :enable-time-picker="false" :format="format" @cleared="handleClear" @closed="handleDateSelection"></VueDatePicker>

                        <div class="input-group-text date_prepend" style="margin-left:10px;margin-right:10px">to</div>

                        <VueDatePicker style="width:210px" class="datepicker_border" v-model="date[item.options + '_end']" placeholder="Select date" :enable-time-picker="false" :format="format" @cleared="handleClear" @closed="handleDateSelection"></VueDatePicker>
                    </div>
                </div>

                <label v-else>{{item.title}}
                    <app-select style="min-width:210px" v-if="item.type === 'select'" placeholder="Select date" :options="form_select_options[item.options]" v-model="form[item.options]">
                    </app-select>

                    <app-select-multi style="min-width:210px" v-if=" item.type === 'multi_select'" :options="form_select_options[item.options]" v-model="form[item.options]">
                    </app-select-multi>

                    <div v-if="item.type === 'datetime_range'">
                        <div class="input-group input-daterange ">
                            <VueDatePicker style="width:210px" class="datepicker_border" v-model="date[item.options + '_start']" :enable-time-picker="false" :format="format"></VueDatePicker>

                            <VueDatePicker class="datepicker_border" style="width:110px ;margin-left:10px" v-model="date[item.options + '_timestart']" time-picker placeholder="00:00"></VueDatePicker>

                            <div class="input-group-text date_prepend" style="margin-left:10px;margin-right:10px">to</div>

                            <VueDatePicker style="width:210px" class="datepicker_border" v-model="date[item.options + '_end']" :enable-time-picker="false" :format="format"></VueDatePicker>
                            <VueDatePicker class="datepicker_border" style="width:110px;margin-left:10px" v-model="date[item.options + '_timeend']" time-picker placeholder="23:59"></VueDatePicker>
                        </div>
                    </div>
                    <div v-if="item.type === 'date_range'">
                        <div class="input-group input-daterange ">
                            <VueDatePicker style="width:210px" class="datepicker_border" v-model="date[item.options + '_start']" placeholder="Select date" :enable-time-picker="false" :format="format"></VueDatePicker>

                            <div class="input-group-text date_prepend" style="margin-left:10px;margin-right:10px">to</div>

                            <VueDatePicker style="width:210px" class="datepicker_border" v-model="date[item.options + '_end']" placeholder="Select date" :enable-time-picker="false" :format="format"></VueDatePicker>
                        </div>
                    </div>
                    <b-form-input v-if="item.type === 'input'" :name="item.id" v-model="form[item.options]" type="search"></b-form-input>

                    <div v-if="item.type === 'checkbox'" class="input-group" style="margin-left:-8px;margin-top:6px;">
                        <b-form-checkbox id="checkbox-1" v-model="form[item.options]" name="checkbox-1"></b-form-checkbox>
                    </div>

                </label>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="d-flex">
                <button type="submit" class="btn btn-primary btn-rounded w-md waves-effect waves-light my-3 mr-1" :disabled="working">
                    <template v-if="working">
                        <i class="fa fa-spin fa-hourglass m-r-5"></i>
                        処理中
                    </template>
                    <template v-else>検索</template>
                </button>
                <button v-if="!noCsvDownload" type="button" class="btn btn-success btn-rounded w-md waves-effect waves-light my-3 mr-1" @click="downloadCsv" :disabled="csvWorking">
                    <template v-if="csvWorking">
                        <i class="fa fa-spin fa-hourglass m-r-5"></i>
                        処理中
                    </template>
                    <template v-else>
                        <i class="fa fa-cloud-download m-r-5"></i>
                        <span>csv出力</span>
                    </template>

                </button>
            </div>
            <button v-if="deleteStocks" type="button" class="btn btn-danger btn-rounded w-md waves-effect waves-light my-3" @click="deleteStocksFun" :disabled="deleteWorking">
                <template v-if="deleteWorking">
                    <i class="fa fa-spin fa-hourglass m-r-5"></i>
                    処理中
                </template>
                <template v-else>
                    <span>検索結果一括出荷</span>
                </template>

            </button>
        </div>
    </div>

</form>
</template>

<script>
import TheSelect from '../TheSelect.vue'
import TheSelectMulti from "../TheSelectMulti.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
    props: ['items', 'form_select_options', 'title', 'locationOverride', 'working', 'csvWorking', 'deleteWorking', 'noCsvDownload', 'deleteStocks'],
    components: {
        'app-select': TheSelect,
        'app-select-multi': TheSelectMulti,
        VueDatePicker
    },
    data() {
        return {
            showOn: true,
            form: {
                'Job.status': [0, 1, 2],
            },
            date: {},
            check: {}
        }
    },
    watch: {
        locationOverride: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal && newVal !== null) {
                    this.form['Stock.locationId'] = newVal

                }
                if (newVal === null) {
                    this.form['Stock.locationId'] = null
                }
                if (newVal === undefined) {
                    return
                }
                let form = this.beforeSubmit();
                this.$emit('fetchSearch', {
                    form
                })
            }

        },
    },
    methods: {
        updateItem(value) {
            if (value === false) {
                const _this = this
                this.items.filter(item => item.type === 'checkbox_and_date').forEach(item => {
                    _this.date[item.options + '_start'] = undefined
                    _this.date[item.options + '_end'] = undefined

                });
            }
        },
        handleDateSelection() {
            const _this = this
            this.items.filter(item => item.type === 'checkbox_and_date').forEach(item => {
                const dateStart = _this.format(_this.date[item.options + '_start'])
                const dateEnd = _this.format(_this.date[item.options + '_end'])
                if (dateStart !== undefined || dateEnd !== undefined) {
                    _this.check[item.options] = true
                }
            })
        },
        handleClear() {
            const _this = this
            this.items.filter(item => item.type === 'checkbox_and_date').forEach(item => {
                const dateStart = _this.format(_this.date[item.options + '_start'])
                const dateEnd = _this.format(_this.date[item.options + '_end'])
                if (dateStart === undefined && dateEnd === undefined) {
                    _this.check[item.options] = false
                }
            });
        },
        beforeSubmit() {
            const _this = this
            const datetime = this.items.filter(item => item.type === 'datetime_range').reduce((acc, item) => {
                const dateStart = _this.format(_this.date[item.options + '_start'])
                var timeStart = _this.timeFormat(_this.date[item.options + '_timestart'])
                const dateEnd = _this.format(_this.date[item.options + '_end'])
                var timeEnd = _this.timeFormat(_this.date[item.options + '_timeend'])
                if (timeStart === undefined || timeStart === null) {
                    timeStart = '00:00'
                }
                if (timeEnd === undefined || timeEnd === null) {
                    timeEnd = '23:59'
                }
                if (dateStart !== undefined && timeStart !== undefined && dateEnd !== undefined && timeEnd !== undefined &&
                    dateStart !== null && timeStart !== null && dateEnd !== null && timeEnd !== null) {
                    return {
                        ...acc,
                        [item.options]: dateStart + " " + timeStart + "#" + dateEnd + " " + timeEnd
                    }
                } else {
                    if (dateStart !== undefined && timeStart !== undefined &&
                        dateStart !== null && timeStart !== null) {
                        return {
                            ...acc,
                            [item.options]: dateStart + " " + timeStart + "#"
                        }
                    }
                    if (dateEnd !== undefined && timeEnd !== undefined &&
                        dateEnd !== null && timeEnd !== null) {
                        return {
                            ...acc,
                            [item.options]: "#" + dateEnd + " " + timeEnd
                        }
                    }
                    return acc
                }
            }, {});
            const date = this.items.filter(item => item.type === 'date_range').reduce((acc, item) => {
                const dateStart = _this.format(_this.date[item.options + '_start'])
                const dateEnd = _this.format(_this.date[item.options + '_end'])

                if (dateStart !== undefined && dateEnd !== undefined &&
                    dateStart !== null && dateEnd !== null) {
                    return {
                        [item.options]: dateStart + "#" + dateEnd
                    }
                } else {
                    if (dateStart !== undefined &&
                        dateStart !== null) {
                        return {
                            [item.options]: dateStart + "#"
                        }
                    }
                    if (dateEnd !== undefined &&
                        dateEnd !== null) {
                        return {
                            [item.options]: "#" + dateEnd
                        }
                    }
                    return {}
                }
            }, {});

            const checkAndDate = this.items.filter(item => item.type === 'checkbox_and_date').reduce((acc, item) => {
                const dateStart = _this.format(_this.date[item.options + '_start'])
                const dateEnd = _this.format(_this.date[item.options + '_end'])
                if (dateStart !== undefined && dateEnd !== undefined &&
                    dateStart !== null && dateEnd !== null) {
                    return {
                        [item.options]: dateStart + "#" + dateEnd
                    }
                } else {
                    if (dateStart !== undefined &&
                        dateStart !== null) {
                        return {
                            [item.options]: dateStart + "#"
                        }
                    }
                    if (dateEnd !== undefined &&
                        dateEnd !== null) {
                        return {
                            [item.options]: "#" + dateEnd
                        }
                    }
                    if (_this.check[item.options] === true) {
                        return {
                            [item.options]: "#"
                        }
                    }
                    return {}
                }
            }, {});

            const form = {
                ...this.form,
                ...datetime,
                ...date,
                ...checkAndDate
            };
            return form;
        },
        downloadCsv() {
            //TODO
            let form = this.beforeSubmit();
            this.$emit('fetchCsv', {
                form
            })
        },
        deleteStocksFun() {
            let form = this.beforeSubmit();
            this.$emit('deleteStocksFun', {
                form
            })
        },
        submit() {
            let form = this.beforeSubmit();
            this.$emit('fetchSearch', {
                form
            })
        },
        format(date) {
            if (date === undefined || date === null) {
                return undefined
            }
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${year}-${this.prefix(month)}-${this.prefix(day)}`
        },
        timeFormat(time) {
            if (time === undefined || time === null) {
                return undefined
            }
            return `${this.prefix(time.hours)}:${this.prefix(time.minutes)}`
        },
        prefix(str) {
            var len = str.toString().length;
            while (len < 2) {
                str = "0" + str;
                len++;
            }
            return str;
        }
    }
}
</script>

<style>
.date_prepend {
    border: 0;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}

.form_container {
    min-height: 0px
}

.datepicker {
    z-index: 100
}

.datepicker_border {
    width: 150px;
}
</style>
