<template>
    <panZoom v-if="warehouse !== null" selector="#scene" id="panzoom" @init="initPanzoom" @zoom="updateLines" @pan="updateVisible" :options="{minZoom: 1, maxZoom: 150, bounds: true, fixedContainer: 'content', boundsPadding: 0, filterKey: _=> {return true}, onTouch: _=> {return false}}">
        <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                ref="svg_history"
                :width="width"
                :height="height"
                id="svg-map"
                style="z-index: 2"
        >
            <g id='scene'>
                <g id="svg-fix" :transform="`scale(`+warehouse.real_width/warehouse.width+`,`+warehouse.real_height/warehouse.height+`)`">
                    <image :xlink:href="signedURL" :width="warehouse.width" :height="warehouse.height" />
                </g>

                <g id='elements' :transform="`translate(`+warehouse.real_width * (warehouse.border_left_x+warehouse._user_border_left_x)+`,`+warehouse.real_height * (warehouse.border_left_y+warehouse._user_border_left_y)+`)`">
                    <template v-for="(location, index) in warehouse.locations">
                    <g
                            v-if="(location._status !== undefined && location._status !== `delete` && location._status !== `ignore`) && (serializing || (
                                        visible_area.top < (location.y + location._batch_y + location.height/2) && visible_area.left < (location.x + location._batch_x + location.width/2)
                                        && visible_area.bottom > (location.y + location._batch_y + location.height/2) && visible_area.right > (location.x + location._batch_x + location.width/2)
                                        ))
                                        "
                            v-bind:key="location._element_id"
                    >
                        <rect
                                :id="location._element_id"
                                :transform="`rotate(`+(parseFloat(location.rotation)+parseFloat(location._batch_r)+360%360)+`,`+(location.x + location._batch_x + (location.width + location._batch_w)/2) * warehouse._scale_x+`,`+(location.y + location._batch_y + (location.height + location._batch_h)/2) * warehouse._scale_y+`) translate(`+(location.x + location._batch_x) * warehouse._scale_x+`,`+(location.y + location._batch_y) * warehouse._scale_y+`)`"
                                :width="(location.width + location._batch_w) * warehouse._scale_x"
                                :height="(location.height + location._batch_h) * warehouse._scale_y"
                                :fill="(location._selected ? active_selection.color :  (location === shelf_selected ? `lightblue` : (location === shelf_selected_nudge ? `pink` : location._fill)))"
                                :stroke="item_target === location ? `red` : (location._border ? location._border.stroke : `black`)"
                                :stroke-width="item_target === location ? line_width * 2 : (location._border ? location._border.line_width * line_width : line_width)"
                                :stroke-dasharray="item_target === location ? `` : (line_width*2 + ` ` +line_width*2)"
                                class="shelf"
                                :data-tabindex="index"
                        />

                        <text
                                v-if="(!drag_mode || drag_obj === location) && item_scale < 0.03 || serializing "
                                :transform="`translate(`+(location.x + location._batch_x + location.width/2) * warehouse._scale_x+`,`+(location.y + location._batch_y + location.height/2 + item_scale * 7) * warehouse._scale_y+`) scale(`+item_scale+`,`+item_scale+`)`"
                                :style="`font-size: 0.5rem; pointer-events: none;`+capabilities.location_select_only?`opacity:0.2`:``"
                                text-anchor="middle"
                        >
                            {{ location.label }}
                        </text>
                    </g>
                    </template>
                    <template v-if="!capabilities.location_select_only && item_scale < 0.06">
                        <template v-for="tag in warehouse.tags">
                        <g
                                
                                v-if="item_selected === tag || tag._status !== `delete` && tag._status !== `ignore` && (serializing || ( visible_area.top < (tag.y + tag._batch_y) && visible_area.left < (tag.x + tag._batch_x) && visible_area.bottom > (tag.y + tag._batch_y) && visible_area.right > (tag.x + tag._batch_x)))"
                                v-bind:key="tag._element_id"
                                :transform="`translate(`+((tag.x + tag._batch_x) * warehouse._scale_x - item_scale*12)+`,`+(tag.y + tag._batch_y) * warehouse._scale_y+`)`"
                                :style="item_mode || tag._location === shelf_selected ? `pointer-events: all;`:`pointer-events: none;`"
                                :id="tag._element_id"
                        >
                            <location-tag
                                    :transform="`scale(`+item_scale+`,`+item_scale+`) rotate(45 12.00000000000001,14.499771118164059)`"
                                    :fill="item_selected === tag ? `red`:`black`"
                                    :_style="item_mode || tag._location === shelf_selected ? `pointer-events: all;`:`pointer-events: none;`"
                            />
                            <text
                                    :transform="`scale(`+item_scale+`,`+item_scale+`) translate(12.5,35)`"
                                    style="font-size: 0.8rem; pointer-events: none;"
                                    text-anchor="middle"
                            >
                                {{ tag.label }}
                            </text>
                        </g>
                        </template>
                    </template>
                </g>
            </g>
        </svg>
    </panZoom>
</template>

<script>
    import { Warehouse, Location, Tag, Checkpoint, Selection } from '../modal/Warehouse'
    import { Drag, Drop } from 'vue3-drag-drop';
    import LocationTag from "./LocationTag.vue";
    import { Storage } from 'aws-amplify';

    export default {
        name: "FloorMapReadOnly",

        components: {
            LocationTag, Drag, Drop
        },
        props: {
            floormap: Warehouse,
            history_compare: Boolean,
            width: Number,
            height: Number,
            offset_x: Number,
            offset_y: Number,
        },
        data() {
            return {
                capabilities: {
                    save: true,
                    buttons: true,
                    zoom: true,
                    boundary_only: false,
                    location_select_only: false,
                    zoom_buttons: true,
                    snap: true,
                    counts: true,
                    history_compare: false,
                },

                warehouse: null,
                zoom: null,
                zoom_active: false,
                zoom_timer: null,
                zoom_mode: null,
                line_width: 0.01,
                handlebar_width: 0.01,
                item_scale: 0.001,
                svg: null,
                local: null,
                working: false,
                timer: new Date().getTime(),
                serializing: false,
                count_location: 0,
                count_tag: 0,

                // history
                history_display: false,
                history_data: null,
                history_value: [],
                history_disable_leaf: null,
                history_ignore_deselect: false,

                // this value later gets set with the default zoom level so that we can calculate changes in zoom by percent
                zoom_fit: 1,
                visible_area: {
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0
                },

                // current #elements coordinates (in meters) of mouse position
                svg_x: 0,
                svg_y: 0,

                // drag_mode determines how changes in the svg mouse position will affect objects
                drag_mode: null,
                drag_obj: null,
                drag_trash: false,
                drag_will_trash: false,
                drag_oob_x: 0,
                drag_oob_y: 0,
                drag_x: 0,
                drag_y: 0,
                drag_item_x: 0,
                drag_item_y: 0,
                drag_offset_x: 0,
                drag_offset_y: 0,
                drag_obj_origin_x: 0,
                drag_obj_origin_y: 0,
                drag_corner: null,
                drag_bounds: null,

                shelf_selected: null,
                shelf_selected_controls: {
                    x: 0,
                    y: 0,
                    w: 0,
                    h: 0,
                    r: 0,
                    c: {x: 0, y: 0},
                },
                shelf_selecting: null,
                shelf_selected_nudge: null,

                map_selecting: null,
                map_selected: false,
                map_select_flag: false,

                // item controls
                item_mode: false,
                item_next_index: 0,
                item_target: null,
                item_selected: null,
                item_selected_label: {label: '', old_label: '', disabled: true, not_found: false, item: null},

                shared_batch: null,
                shared_batch_target: null,
                shared_batch_operation: null,
                shared_batch_on_end: null,

                allow_drag_new: false,
                info_text: null,

                active_selection: null,
                mouse_status: false,
                _timer: null,
                copy_objects: [],
                after_render: [],
                signedURL:"",
            };
        },
        updated: function () {
            let _this = this
            this.$nextTick(function () {
                while (_this.after_render.length)
                    (_this.after_render.shift())()
            })
        },
        mounted() {
            // prepare the data for map usage
            this.warehouse = this.floormap
            this.getMap()
            this.working = true
            setTimeout(this.loadWarehouse, 20)
        },
        watch: {
            floormap: {
                handler(newVal, oldVal) {
                    if (!newVal) return
                    this.warehouse = newVal
                }
            },
        },
        methods: {
            async getMap(){
                if(this.warehouse.upload !== undefined && this.warehouse.upload !== null){
                    if(this.warehouse.upload.includes("private/")){
                        this.signedURL = await Storage.get(this.warehouse.upload.slice(8), { level: 'private' }); 
                    } else if(this.warehouse.upload.includes("protected/")){
                        this.signedURL = await Storage.get(this.warehouse.upload.slice(10), { level: 'protected' }); 
                    } else if(this.warehouse.upload.includes("public/")){
                        this.signedURL = await Storage.get(this.warehouse.upload.slice(7)); 
                    }
                }
                
            },
            initPanzoom(e) {
                this.zoom = e
            },

            loadWarehouse() {
                let _this = this
                this.resizeCanvas()
                this.svg = document.getElementById('svg-map')
                this.local = document.getElementById('elements')

                document.onkeydown = function (e) {
                    if (this.shelf_selected_nudge !== null && e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                        e.view.event.preventDefault();
                    }
                }
                this.warehouse.calculateScale()
                this.working = false
            },

            resizeCanvas() {
                let _this = this
                setTimeout(_ => {
                    setTimeout(_this.fitWarehouseToZoom, 10)
                }, 500)
            },

            fitWarehouseToZoom() {
                if (this.zoom === null) return setTimeout(this.fitWarehouseToZoom, 100)

                // for some reason need to move twice...
                this.zoom.moveTo(0, 0);
                this.zoom_fit = Math.min(this.width / this.warehouse.real_width, this.height / this.warehouse.real_height)
                this.zoom.zoomTo(0, 0, this.zoom_fit)
                this.zoom.moveTo(0, 0);
            },
            updateBounds() {
                this.warehouse.border_left_x  = parseFloat(this.warehouse._user_border_left_x)
                this.warehouse.border_left_y  = parseFloat(this.warehouse._user_border_left_y)
                this.warehouse.border_right_x = parseFloat(this.warehouse._user_border_right_x)
                this.warehouse.border_right_y = parseFloat(this.warehouse._user_border_right_y)
                this.warehouse.calculateScale()
            },
            calculateScale() {
                let left_x = this.border_left_x + this._user_border_left_x
                let left_y = this.border_left_y + this._user_border_left_y
                let right_x = this.border_right_x + this._user_border_right_x
                let right_y = this.border_right_y + this._user_border_right_y

                this._scale_fit_x             = this.real_width * Math.max(1 - left_x - right_x, 0.1) // sanity check
                this._scale_fit_y             = this.real_height * Math.max(1 - left_y - right_y, 0.1) // sanity check
                this._scale_x                 = this._scale_fit_x / this.real_width
                this._scale_y                 = this._scale_fit_y / this.real_height
            },
            updateVisible(e) {
                let transform = e.getTransform()

                let bounds = {
                    left: (-this.line_width * transform.x - this.warehouse.real_width * this.warehouse.border_left_x) / this.warehouse._scale_x,
                    top: (-this.line_width * transform.y - this.warehouse.real_height * this.warehouse.border_left_y)  / this.warehouse._scale_y,
                }

                bounds.right = bounds.left + (this.width * this.line_width + this.warehouse.real_width * this.warehouse.border_left_x) / this.warehouse._scale_x
                bounds.bottom = bounds.top + (this.height * this.line_width + this.warehouse.real_height * this.warehouse.border_left_y) / this.warehouse._scale_y

                if (bounds.left < 0) bounds.left = 0
                if (bounds.top < 0) bounds.top = 0

                //console.log(bounds, this.width * this.line_width)
                this.visible_area = bounds
            },
            updateLines(e) { // keeps the lines drawn to 1px
                this.updateVisible(e)
                let currentScale = e.getTransform().scale
                let regularZoom = this.width / (this.warehouse.real_width * (1 + this.warehouse.border_left_x + this.warehouse.border_right_x))
                this.line_width = regularZoom / currentScale * (this.warehouse.real_width * (1 + this.warehouse.border_left_x + this.warehouse.border_right_x)) / this.width
                this.handlebar_width = this.line_width * 40
                this.item_scale = 1/(0.85/this.line_width)
            },
        }
    }
</script>

<style scoped>

</style>