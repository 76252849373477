<template>
<footer class="footer">
    2023 © RF Locus
</footer>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
