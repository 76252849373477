<template>
    <b-button
        pill variant="primary"
        @event="$emit('click')"
    >
        <slot><i class="fa fa-plus-square"></i></slot>
    </b-button>
</template>

<script>
    export default {
        name: "ButtonAdd",
    }
</script>

<style scoped>

</style>
