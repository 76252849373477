<template>
<div>
    <template v-if="state.step===0">
        <b-form-input v-model="state.warehouse.name" placeholder="無題のマップ"></b-form-input>
        <b-card class="my-4" align="center" footer-class="d-flex justify-content-end">
            <b-card-text>
                <div>
                    <div v-if="state.files.length > 0">
                        <div class="files">
                            <div class="file-item" v-for="(file, index) in state.files" :key="index">
                                <span>{{ file.name }}</span>
                                <ButtonDelete @click="handleClickDeleteFile(index)"></ButtonDelete>
                            </div>
                        </div>
                        <img :src="state.svgFile" alt="SVG" class="responsive-img" />
                    </div>
                    <div v-else class="dropzone" v-bind="getRootProps()">
                        <div class="border-dropzone">
                            <input v-bind="getInputProps()" />
                            <h4>間取り図をアップロード</h4>
                            <div>
                                <i class="fa fa-upload"></i>
                            </div>
                        </div>
                    </div>

                </div>
            </b-card-text>
            <template v-slot:footer>
                <b-button class="mx-2" variant="primary" @click="$router.push(`/3/${this.$route.params.menuId}/1?warehouse=${this.$route.query.warehouse}`)">戻る</b-button>
                <b-button variant="primary" @click="move(1)">次へ</b-button>
            </template>
        </b-card>
        <b-alert v-model="state.validateError" variant="danger"><a href="#" class="alert-link">Please enter a map name and upload a file</a></b-alert>
    </template>

    <template v-if="state.step===1">
        <h2>フロアの領域を指定してください</h2>
        <b-card class="my-4" align="center" footer-class="d-flex justify-content-end">
            <b-card-text>
                <floor-map :floormap="state.warehouse" mode="boundary" :bottom-shift="-150"></floor-map>
            </b-card-text>
            <template v-slot:footer id="bottom-id">
                <b-button class="mx-2" variant="primary" @click="move(0, true)">戻る</b-button>
                <b-button variant="primary" @click="move(2)">次へ</b-button>
            </template>
        </b-card>
        <b-alert v-model="state.validateError" variant="danger"><a href="#" class="alert-link">Please enter a map name and upload a file</a></b-alert>
    </template>

    <template v-if="state.step===2">
        <h2>フロアの実寸を入力してください</h2>
        <b-card class="my-4" footer-class="d-flex justify-content-end">
            <b-card-text>
                <div>
                    <label>フロア縦横比率</label>　<b-form-input :modelValue="(state.warehouse.real_width / state.warehouse.real_height)" disabled></b-form-input>
                </div>
                <div>
                    <label>Room width (m)</label>　<b-form-input v-model="state.warehouse.real_width"></b-form-input>
                </div>
                <div>
                    <label>Room height (m)</label>　<b-form-input v-model="state.warehouse.real_height"></b-form-input>
                </div>
            </b-card-text>
            <template v-slot:footer>
                <b-button class="mx-2" variant="primary" @click="move(1, true)">戻る</b-button>
                <b-button variant="primary" @click="save">次へ</b-button>
            </template>
        </b-card>
        <b-alert v-model="state.validateError" variant="danger"><a href="#" class="alert-link">Please enter a map name and upload a file</a></b-alert>
    </template>
</div>
</template>

<script>
import {
    reactive,
    watch,
    onMounted
} from 'vue';
import {
    useRoute, useRouter 
} from 'vue-router';
import {
    useDropzone
} from 'vue3-dropzone';
import {
    Warehouse
} from "../../../components/modal/Warehouse";
import AwsApi from "../../../Api"
import * as queries from "../../../graphql/locus_queries";
import * as mutations from "../../../graphql/locus_mutations";
import global from "../../../Global.vue";
import {
    Storage
} from "aws-amplify";

export default {
    name: "UseDropzoneDemo",
    setup() {
        const route = useRoute();
        const router = useRouter();
        var listLocations = []
        var listLocationTags = []

        const state = reactive({
            files: [],
            tenantId: "",
            validateError: false,
            step: 0,
            warehouse: {
                name: ''
            },
            svgFile: ""
        });

        onMounted(() => {
            state.tenantId = localStorage.getItem(global.tenantId);
            fetchWarehouseData()
            if (state.warehouse.upload) {
                //TODO
                // var mockFile = {
                //     name: state.warehouse.upload,
                //     size: state.file.size
                // }; //Any name, size.
                // this.$refs.uploadDropzone.manuallyAddFile(mockFile, this.warehouse.upload);
            }
        });

        function onDrop(acceptFiles, rejectReasons) {
            if (acceptFiles.length === 0) {
                return
            }
            if (acceptFiles[0].type !== "image/svg+xml") {
                return
            }
            state.files = acceptFiles;

            const reader = new FileReader();
            reader.onload = () => {
                state.svgFile = reader.result;

                var i = new Image()
                i.onload = function () {
                    state.warehouse.upload = `public/${state.tenantId}/maps/${route.params.id}/map.svg`
                    state.warehouse.upload_actual = state.svgFile
                    state.warehouse.width = i.width;
                    state.warehouse.height = i.height;
                    state.warehouse.real_width = 10;
                    state.warehouse.real_height = i.height / i.width * 10
                    state.warehouse.default_shelf_width = 1
                    state.warehouse.default_shelf_height = 1
                    state.warehouse.border_left_x = 0
                    state.warehouse.border_left_y = 0
                    state.warehouse.border_right_x = 0
                    state.warehouse.border_right_y = 0
                    state.warehouse.calculateScale()
                };
                i.src = state.svgFile
            };
            reader.readAsDataURL(state.files[0]);

        }

        function handleClickDeleteFile(index) {
            state.files.splice(index, 1);

            state.files = []
            state.warehouse.upload = '';
        }

        async function fetchWarehouseData() {
            const response = await AwsApi.graphql({
                query: queries.getWarehouse,
                variables: {
                    tenantId: state.tenantId,
                    id: route.params.id
                }
            }, this);
            if (response === undefined) {
                return
            }
            if (response.data.getWarehouse !== null) {
                listLocations = []
                listLocationTags = []
                await getListLocations()
                await getListLocationTags()
                response.data.getWarehouse.locations = listLocations
                response.data.getWarehouse.tags = listLocationTags
                state.warehouse = new Warehouse(response.data.getWarehouse)
            }
        }

        async function getListLocations(nextTokenValue) {
            const response = await AwsApi.graphql({
                query: queries.queryLocationsByWarehouseId,
                variables: {
                    tenantId: state.tenantId,
                    warehouseId: {
                        eq: route.params.id
                    },
                    nextToken: nextTokenValue

                }
            }, this);
            if (response === undefined) {
                return
            }
            
            listLocations = listLocations.concat(response.data.queryLocationsByWarehouseId.items);
            if (response.data.queryLocationsByWarehouseId.nextToken !== null) {
                await getListLocations(response.data.queryLocationsByWarehouseId.nextToken)
            }
        }
        async function getListLocationTags(nextTokenValue) {
            const response = await AwsApi.graphql({
                query: queries.queryLocationTagsByWarehouseId,
                variables: {
                    tenantId: state.tenantId,
                    warehouseId: {
                        eq: route.params.id
                    },
                    nextToken: nextTokenValue

                }
            }, this);
            if (response === undefined) {
                return
            }
            
            listLocationTags = listLocationTags.concat(response.data.queryLocationTagsByWarehouseId.items);
            if (response.data.queryLocationTagsByWarehouseId.nextToken !== null) {
                await getListLocationTags(response.data.queryLocationTagsByWarehouseId.nextToken)
            }
        }

        async function move(destination, is_back) {


            if (is_back) state.validateError = null
            else switch (destination) {
                case 1:
                    state.validateError = state.warehouse.name.length === 0 || state.warehouse.upload_actual === '' || state.warehouse.upload_actual === undefined
            }

            if (!state.validateError) {
                if (destination === 1) {
                    if (state.warehouse.upload !== undefined && state.warehouse.upload !== null) {
                        const key = state.warehouse.upload.slice(7)
                        await Storage.put(key, state.files[0], {
                            contentType: "image/svg+xml",
                        })
                    }
                }
                state.step = destination

            }
        }
        async function save() {
            AwsApi.graphql({
                query: mutations.updateWarehouse,
                variables: {
                    input: {
                        tenantId: state.tenantId,
                        id: state.warehouse.id,
                        name: state.warehouse.name,
                        width: state.warehouse.width,
                        height: state.warehouse.height,
                        upload: state.warehouse.upload,
                        real_width: state.warehouse.real_width,
                        real_height: state.warehouse.real_height,
                        default_shelf_width: state.warehouse.default_shelf_width,
                        default_shelf_height: state.warehouse.default_shelf_height
                    }
                }
            }, this).then(() => {
                router.push(`/3/${route.params.menuId}/1?warehouse=${route.query.warehouse}`)

            })
        }

        const {
            getRootProps,
            getInputProps,
        } = useDropzone({
            onDrop
        });

        return {
            getRootProps,
            getInputProps,
            state,
            handleClickDeleteFile,
            move,
            save
        };
    },
};

const state = reactive({
    files: [],
    tenantId: "",
    validateError: false,
    step: 0,
    warehouse: {
        name: ''
    },
});
</script>

<style lang="scss" scoped>
.responsive-img {
    max-width: 100%;
    height: auto;
}

.dropzone,
.files {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-size: 12px;
    line-height: 1.5;
}

.border-dropzone {
    background: #fff;
    font-size: 30px;
    margin: 2em 0;
    font-family: Arial, sans-serif;
    letter-spacing: .2px;
    color: #777;
}

.file-item {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(255 167 18 / 20%);
    padding: 7px;
    padding-left: 15px;
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }

    .delete-file {
        background: red;
        color: #fff;
        padding: 5px 10px;
        border-radius: 8px;
        cursor: pointer;
    }
}
</style>
