import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

import { createI18n } from 'vue-i18n'
import BootstrapVueNext from 'bootstrap-vue-next'

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
// import 'bootstrap/dist/css/bootstrap.min.css'

import './assets/css/bootstrap.css'
import './assets/css/icons.css'
import './assets/css/style.css'

import VWave from 'v-wave'
import messages from './message.json'

import mitt from 'mitt'

import panZoom from 'panzoom';
import PanZoomComponent from './components/panzoom/PanZoom.vue';



import * as uiv from 'uiv'

const i18n = createI18n({
    legacy:false,
    locale: 'jp', // デフォルト言語はjaにしておくが、ブラウザの言語を拾ってきてここに入れる => 言語変更されたら書き換える
    fallbackLocale: 'en',
    messages
})

const app = createApp(App)
app.use(BootstrapVueNext)
app.use(router).use(i18n)
app.use(VWave)
app.use(uiv, {prefix: 'uiv'})
app.component(PanZoomComponent.name, PanZoomComponent);

app.mount('#app')


if (app.hasOwnProperty('config') && app.config.hasOwnProperty('globalProperties')) {
    app.config.globalProperties.$panZoom = panZoom;
}
else {
    app.prototype.$panZoom = panZoom;
}
app.config.globalProperties.$bus = new mitt()