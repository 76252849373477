<template>
    <b-button pill variant="outline-primary mr-2" @event="$emit('click')">
        {{ $t("button.save") }}
    </b-button>
</template>

<script>
    export default {
        name: "ButtonSave",
    }
</script>

<style scoped>

</style>
