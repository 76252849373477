/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const onUpdateProcessJob = /* GraphQL */ `
  subscription OnUpdateProcessJob(
    $filter: ModelSubscriptionProcessJobFilterInput
  ) {
    onUpdateProcessJob(filter: $filter) {
      jobKey
      status
      message
    }
  }
`;