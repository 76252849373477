<template>
    <div>
        <app-header></app-header>
        <div class="content-page" :style="{'margin-left':'40px'}">
            <div class="content">
                <div class="card-box">
                    <h1>Tenant list</h1>
                    <b-table class="m-b-0 nowrap tablesaw"
                    striped
                    bordered
                    outlined
                    hover
                    :select-head="false"
                    scrollable
                    responsive 
                    @row-clicked="rowSelected"
                    :selectable="true"
                    select-mode="single"
                    :items="items"
                    :fields="fields">
                    <template v-slot:cell(add)="data">
                        <ButtonAdd class="mr-1" @click="addUser(data.item.groupname)"></ButtonAdd>
                    </template>
                    
                    </b-table>
                </div>
                <ButtonAdd @click="addUser()"></ButtonAdd>
                <div>
                    <b-modal v-model="tenantModal" :title="selectGroupName? '新規ユーザー' :'新規テナント'" @ok="createTenant">
                        <form>
                            <b-form-group id="name-group" label="ユーザー名:" label-for="name-input" 
                            :invalid="!isNameValid">
                            <b-form-input id="name-input" v-model="newTenant.username" 
                            :class="{ 'is-invalid': !isNameValid }" 
                            autocomplete="off"></b-form-input>
                            <div slot="invalid-feedback">{{error_user}}</div>
                            </b-form-group>

                            <b-form-group id="password-group" label="パスワード:" label-for="password-input" :invalid="!isPasswordValid">
                            <b-form-input id="password-input" type="password" v-model="newTenant.password" 
                            :class="{ 'is-invalid': !isPasswordValid }" 
                            autocomplete="new-password" 
                            required></b-form-input>
                            <div slot="invalid-feedback">{{error_password}}</div>
                            </b-form-group>

                            <b-form-group id="confirm-password-group" label="パスワードの確認：" label-for="confirm-password-input" :invalid="!isPasswordMatch">
                            <b-form-input id="confirm-password-input" type="password" v-model="newTenant.confirmPassword" 
                            :class="{ 'is-invalid': !isPasswordMatch }"></b-form-input>
                            <div slot="invalid-feedback">{{ error_confirm_password}}</div>
                            </b-form-group>

                            <b-form-group id="email-group" label="電子メールアドレス：" label-for="email-input">
                            <b-form-input id="email-input" type="email" v-model="newTenant.email"></b-form-input>
                            </b-form-group>

                            <b-form-group id="remark-group" label="備考:" label-for="remark-input">
                            <b-form-input id="remark-input" v-model="newTenant.description"></b-form-input>
                            </b-form-group>

                        </form>
                    </b-modal>
                </div>
            </div>
            <app-footer></app-footer>
        </div>
        <!-- <div class="content">
            <h1>Tenant list</h1>
            <b-table striped hover :items="items"></b-table>
        </div>
      -->
    </div>
</template>

<script>
import AppHeader from "../layout/Header.vue"
import AppFooter from "../layout/Footer.vue"
import ButtonAdd from "../../components/buttons/ButtonAdd.vue";
import {API} from "aws-amplify";
import {listGroupsAndUsers} from "../../graphql/queries";
import {createUser} from "../../graphql/mutations";


export default{
    name:"AdminView",
    async created(){
        this.listGroups();
    },
    components:{
        AppHeader,
        AppFooter,
        ButtonAdd
    },

    data() {
        return{
            items: [],
            fields: [
                {
                key: "no",
                label: "No",
                sortable: false,
                },
                {
                key: "groupname",
                label: "テナント",
                sortable: true,
                },
                {
                key: "createdAt",
                label: "作成日時",
                sortable: true,
                },
                {
                key: "description",
                label: "備考",
                sortable: false,
                },
                {
                key: "users",
                label: "ユーザ",
                sortable: false,
                },
                {
                key: "add",
                label: "ユーザ追加",
                sortable: false,
                },
            ],
            newTenant:{
                "username":"",
                "password":"",
                "email":"",
                "confirmPassword": "",
                "description":""
            },
            isNameValid:true,
            error_user:"ユーザー名を入力してください。",
            isPasswordValid:true,
            error_password:"パスワードは英数字を含み、8桁以上である必要があります。",
            isPasswordMatch:true,
            error_confirm_password:"パスワードが一致しません。",
            tenantModal: false,
            selectGroupName: ""
        };
    },
//     computed: {
        
//   },
    methods:{
        isFormValid(){
            if(!this.newTenant.username){
                this.isNameValid = false
                return false
            }
            this.isNameValid = true

            const regex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/
            if(!regex.test(this.newTenant.password)){
                this.isPasswordValid = false
                return false
            }
            this.isPasswordValid = true

            if(this.newTenant.password != this.newTenant.confirmPassword){
                this.isPasswordMatch = false
                return false
            }
            this.isPasswordMatch = true

            return true

        },
        async listGroups(){
            const response = await API.graphql({
                    query: listGroupsAndUsers
                });

            //console.log(response.data.listGroupsAndUsers)
            var index = 1
            this.items =  response.data.listGroupsAndUsers
            .filter(item =>item["groupname"] != "user" && item["groupname"] != "Admins")
            .map(item =>{
                let users = item["users"].map(user=>{ return user["username"]}).join(",")
                return{
                    ...item,
                    "no":index++,
                    "users":users
                }
            });

        },

        rowSelected(item) {
        },

        async createTenant(bvModalEvent){
            console.log(this.newTenant)

            
            if(!this.isFormValid()){
                bvModalEvent.preventDefault()
                return
            }
            var variables = {
                                "username": this.newTenant.username,
                                "password":this.newTenant.password,
                                "email":this.newTenant.email,
                                "description":this.newTenant.description
                            }
            if(this.selectGroupName){
                variables.groupname = this.selectGroupName
            }
            const response = await API.graphql({
                            query: createUser,
                            variables: variables
                        })
                        console.log(response)
            this.listGroups()         

        },
        addUser(groupname){
            this.selectGroupName = groupname
            this.tenantModal = true

            this.newTenant = {
                "username":"",
                "password":"",
                "email":"",
                "confirmPassword": "",
                "description":""
            }
        }
    }
    

}
</script>