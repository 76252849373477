<template>
<app-page url="job/1" settings="jobSettings" title="Job" function_id="1" translate="job_japanese" modalItemId="modalItem">
</app-page>
</template>

<script>
import Page from './Page.vue'
export default {
    components: {
        'app-page': Page,
    }
}
</script>
