<template>
<div class="stock-page1">
    <app-page url="stock2" settings="stockSettings" title="Stocks2" :use-map="true">
    </app-page>
</div>
</template>

<script>
import StockPage from './StockPage.vue'
export default {
    components: {
        'app-page': StockPage,
    },

}
</script>

<style lang="scss">
.table-active {
    background-color: unset;
}

.stock-page1 {
    width: 100%;
    display: flex;
    overflow-x: auto;
}

.stock-page1 .use-map {

    .table-striped>tbody>tr:nth-child(odd).selected>td,
    .table-striped>tbody>tr:nth-child(odd).selected>th,
    .table-striped>tbody>tr:nth-child(even).selected>td,
    .table-striped>tbody>tr:nth-child(even).selected>th {
        background-color: rgb(253, 253, 125) !important;
        --bs-table-accent-bg: rgb(253, 253, 125) !important;
    }

}

</style>
